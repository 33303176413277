import Turnstone from 'turnstone'
import recentSearchesPlugin from 'turnstone-recent-searches'

const listbox = {
    displayField: 'display_name',
    data: [
      {
        "code": "SBAA",
        "name": "Conceição Do Araguaia",
        "display_name": "Conceição Do Araguaia, Conceição Do Araguaia, Brasil",
        "city": "Conceição Do Araguaia",
        "state": "PA"
      },
      {
        "code": "SBAE",
        "name": "Bauru/Arealva",
        "display_name": "Bauru/Arealva, Bauru, Brasil",
        "city": "Bauru",
        "state": "SP"
      },
      {
        "code": "SBAQ",
        "name": "Bartolomeu De Gusmão",
        "display_name": "Bartolomeu De Gusmão, Araraquara, Brasil",
        "city": "Araraquara",
        "state": "SP"
      },
      {
        "code": "SBAR",
        "name": "Santa Maria",
        "display_name": "Santa Maria, Aracaju, Brasil",
        "city": "Aracaju",
        "state": "SE"
      },
      {
        "code": "SBAT",
        "name": "Piloto Osvaldo Marques Dias",
        "display_name": "Piloto Osvaldo Marques Dias, Alta Floresta, Brasil",
        "city": "Alta Floresta",
        "state": "MT"
      },
      {
        "code": "SBAU",
        "name": "Araçatuba",
        "display_name": "Araçatuba, Araçatuba, Brasil",
        "city": "Araçatuba",
        "state": "SP"
      },
      {
        "code": "SBAX",
        "name": "Romeu Zema",
        "display_name": "Romeu Zema, Araxá, Brasil",
        "city": "Araxá",
        "state": "MG"
      },
      {
        "code": "SBBE",
        "name": "Internacional De Belém/Val De Cans/Júlio Cezar Ribeiro",
        "display_name": "Internacional De Belém/Val De Cans/Júlio Cezar Ribeiro, Belém, Brasil",
        "city": "Belém",
        "state": "PA"
      },
      {
        "code": "SBBG",
        "name": "Comandante Gustavo Kraemer",
        "display_name": "Comandante Gustavo Kraemer, Bagé, Brasil",
        "city": "Bagé",
        "state": "RS"
      },
      {
        "code": "SBBH",
        "name": "Pampulha - Carlos Drummond De Andrade",
        "display_name": "Pampulha - Carlos Drummond De Andrade, Belo Horizonte, Brasil",
        "city": "Belo Horizonte",
        "state": "MG"
      },
      {
        "code": "SBBI",
        "name": "Bacacheri",
        "display_name": "Bacacheri, Curitiba, Brasil",
        "city": "Curitiba",
        "state": "PR"
      },
      {
        "code": "SBBP",
        "name": "Estadual Arthur Siqueira",
        "display_name": "Estadual Arthur Siqueira, Bragança Paulista, Brasil",
        "city": "Bragança Paulista",
        "state": "SP"
      },
      {
        "code": "SBBR",
        "name": "Presidente Juscelino Kubitschek",
        "display_name": "Presidente Juscelino Kubitschek, Brasília, Brasil",
        "city": "Brasília",
        "state": "DF"
      },
      {
        "code": "SBBU",
        "name": "Bauru",
        "display_name": "Bauru, Bauru, Brasil",
        "city": "Bauru",
        "state": "SP"
      },
      {
        "code": "SBBV",
        "name": "Atlas Brasil Cantanhede",
        "display_name": "Atlas Brasil Cantanhede, Boa Vista, Brasil",
        "city": "Boa Vista",
        "state": "RR"
      },
      {
        "code": "SBBW",
        "name": "Barra Do Garças",
        "display_name": "Barra Do Garças, Barra Do Garças, Brasil",
        "city": "Barra Do Garças",
        "state": "MT"
      },
      {
        "code": "SBCA",
        "name": "Coronel Adalberto Mendes Da Silva",
        "display_name": "Coronel Adalberto Mendes Da Silva, Cascavel, Brasil",
        "city": "Cascavel",
        "state": "PR"
      },
      {
        "code": "SBCB",
        "name": "Cabo Frio",
        "display_name": "Cabo Frio, Cabo Frio, Brasil",
        "city": "Cabo Frio",
        "state": "RJ"
      },
      {
        "code": "SBCD",
        "name": "Carlos Alberto Da Costa Neves",
        "display_name": "Carlos Alberto Da Costa Neves, Caçador, Brasil",
        "city": "Caçador",
        "state": "SC"
      },
      {
        "code": "SBCF",
        "name": "Tancredo Neves",
        "display_name": "Tancredo Neves, Confins, Brasil",
        "city": "Confins",
        "state": "MG"
      },
      {
        "code": "SBCG",
        "name": "Campo Grande",
        "display_name": "Campo Grande, Campo Grande, Brasil",
        "city": "Campo Grande",
        "state": "MS"
      },
      {
        "code": "SBCH",
        "name": "Serafin Enoss Bertaso",
        "display_name": "Serafin Enoss Bertaso, Chapecó, Brasil",
        "city": "Chapecó",
        "state": "SC"
      },
      {
        "code": "SBCI",
        "name": "Brigadeiro Lysias Augusto Rodrigues",
        "display_name": "Brigadeiro Lysias Augusto Rodrigues, Carolina, Brasil",
        "city": "Carolina",
        "state": "MA"
      },
      {
        "code": "SBCJ",
        "name": "Carajás",
        "display_name": "Carajás, Parauapebas, Brasil",
        "city": "Parauapebas",
        "state": "PA"
      },
      {
        "code": "SSIM",
        "name": "Forquilhinha/Criciúma (Sbcm*)",
        "display_name": "Forquilhinha/Criciúma (Sbcm*), Forquilhinha, Brasil",
        "city": "Forquilhinha",
        "state": "SC"
      },
      {
        "code": "SBCN",
        "name": "Nelson Rodrigues Guimarães",
        "display_name": "Nelson Rodrigues Guimarães, Caldas Novas, Brasil",
        "city": "Caldas Novas",
        "state": "GO"
      },
      {
        "code": "SBCP",
        "name": "Bartolomeu Lisandro",
        "display_name": "Bartolomeu Lisandro, Campos Dos Goytacazes, Brasil",
        "city": "Campos Dos Goytacazes",
        "state": "RJ"
      },
      {
        "code": "SBCR",
        "name": "Corumbá",
        "display_name": "Corumbá, Corumbá, Brasil",
        "city": "Corumbá",
        "state": "MS"
      },
      {
        "code": "SBCT",
        "name": "Afonso Pena",
        "display_name": "Afonso Pena, Curitiba, Brasil",
        "city": "Curitiba",
        "state": "PR"
      },
      {
        "code": "SBCX",
        "name": "Hugo Cantergiani",
        "display_name": "Hugo Cantergiani, Caxias Do Sul, Brasil",
        "city": "Caxias Do Sul",
        "state": "RS"
      },
      {
        "code": "SBCY",
        "name": "Marechal Rondon",
        "display_name": "Marechal Rondon, Várzea Grande, Brasil",
        "city": "Várzea Grande",
        "state": "MT"
      },
      {
        "code": "SSCL",
        "name": "Cassilândia",
        "display_name": "Cassilândia, Cassilândia, Brasil",
        "city": "Cassilândia",
        "state": "MS"
      },
      {
        "code": "SBDB",
        "name": "Bonito",
        "display_name": "Bonito, Bonito, Brasil",
        "city": "Bonito",
        "state": "MS"
      },
      {
        "code": "SBDN",
        "name": "Presidente Prudente",
        "display_name": "Presidente Prudente, Presidente Prudente, Brasil",
        "city": "Presidente Prudente",
        "state": "SP"
      },
      {
        "code": "SBDO",
        "name": "Dourados",
        "display_name": "Dourados, Dourados, Brasil",
        "city": "Dourados",
        "state": "MS"
      },
      {
        "code": "SBEG",
        "name": "Eduardo Gomes",
        "display_name": "Eduardo Gomes, Manaus, Brasil",
        "city": "Manaus",
        "state": "AM"
      },
      {
        "code": "SBEK",
        "name": "Jacareacanga",
        "display_name": "Jacareacanga, Jacareacanga, Brasil",
        "city": "Jacareacanga",
        "state": "PA"
      },
      {
        "code": "SBFI",
        "name": "Cataratas",
        "display_name": "Cataratas, Foz Do Iguaçu, Brasil",
        "city": "Foz Do Iguaçu",
        "state": "PR"
      },
      {
        "code": "SBFL",
        "name": "Hercílio Luz",
        "display_name": "Hercílio Luz, Florianópolis, Brasil",
        "city": "Florianópolis",
        "state": "SC"
      },
      {
        "code": "SBFN",
        "name": "Fernando De Noronha",
        "display_name": "Fernando De Noronha, Fernando De Noronha, Brasil",
        "city": "Fernando De Noronha",
        "state": "PE"
      },
      {
        "code": "SBFZ",
        "name": "Pinto Martins",
        "display_name": "Pinto Martins, Fortaleza, Brasil",
        "city": "Fortaleza",
        "state": "CE"
      },
      {
        "code": "SBGL",
        "name": "Rio De Janeiro/Galeão – Antônio Carlos Jobim",
        "display_name": "Rio De Janeiro/Galeão – Antônio Carlos Jobim, Rio De Janeiro, Brasil",
        "city": "Rio De Janeiro",
        "state": "RJ"
      },
      {
        "code": "SBGM",
        "name": "Guajará-Mirim",
        "display_name": "Guajará-Mirim, Guajará-Mirim, Brasil",
        "city": "Guajará-Mirim",
        "state": "RO"
      },
      {
        "code": "SBGO",
        "name": "Santa Genoveva/Goiânia",
        "display_name": "Santa Genoveva/Goiânia, Goiânia, Brasil",
        "city": "Goiânia",
        "state": "GO"
      },
      {
        "code": "SBGR",
        "name": "Guarulhos - Governador André Franco Montoro",
        "display_name": "Guarulhos - Governador André Franco Montoro, Guarulhos, Brasil",
        "city": "Guarulhos",
        "state": "SP"
      },
      {
        "code": "SSGG",
        "name": "Aeroporto Regional De Guarapuava - Tancredo Thomás De Faria",
        "display_name": "Aeroporto Regional De Guarapuava - Tancredo Thomás De Faria, Guarapuava, Brasil",
        "city": "Guarapuava",
        "state": "PR"
      },
      {
        "code": "SBGV",
        "name": "Coronel Altino Machado",
        "display_name": "Coronel Altino Machado, Governador Valadares, Brasil",
        "city": "Governador Valadares",
        "state": "MG"
      },
      {
        "code": "SBGW",
        "name": "Guaratinguetá",
        "display_name": "Guaratinguetá, Guaratinguetá, Brasil",
        "city": "Guaratinguetá",
        "state": "SP"
      },
      {
        "code": "SBHT",
        "name": "Altamira",
        "display_name": "Altamira, Altamira, Brasil",
        "city": "Altamira",
        "state": "PA"
      },
      {
        "code": "SBIC",
        "name": "Itacoatiara",
        "display_name": "Itacoatiara, Itacoatiara, Brasil",
        "city": "Itacoatiara",
        "state": "AM"
      },
      {
        "code": "SBIH",
        "name": "Itaituba",
        "display_name": "Itaituba, Itaituba, Brasil",
        "city": "Itaituba",
        "state": "PA"
      },
      {
        "code": "SBIL",
        "name": "Bahia - Jorge Amado",
        "display_name": "Bahia - Jorge Amado, Ilhéus, Brasil",
        "city": "Ilhéus",
        "state": "BA"
      },
      {
        "code": "SBIP",
        "name": "Usiminas",
        "display_name": "Usiminas, Santana Do Paraíso, Brasil",
        "city": "Santana Do Paraíso",
        "state": "MG"
      },
      {
        "code": "SBIZ",
        "name": "Prefeito Renato Moreira",
        "display_name": "Prefeito Renato Moreira, Imperatriz, Brasil",
        "city": "Imperatriz",
        "state": "MA"
      },
      {
        "code": "SBJD",
        "name": "Comandante Rolim Adolfo Amaro",
        "display_name": "Comandante Rolim Adolfo Amaro, Jundiaí, Brasil",
        "city": "Jundiaí",
        "state": "SP"
      },
      {
        "code": "SBJF",
        "name": "Francisco De Assis",
        "display_name": "Francisco De Assis, Juiz De Fora, Brasil",
        "city": "Juiz De Fora",
        "state": "MG"
      },
      {
        "code": "SBJI",
        "name": "Ji-Paraná",
        "display_name": "Ji-Paraná, Ji-Paraná, Brasil",
        "city": "Ji-Paraná",
        "state": "RO"
      },
      {
        "code": "SBJP",
        "name": "Presidente Castro Pinto",
        "display_name": "Presidente Castro Pinto, João Pessoa, Brasil",
        "city": "João Pessoa",
        "state": "PB"
      },
      {
        "code": "SBJR",
        "name": "Jacarepaguá - Roberto Marinho",
        "display_name": "Jacarepaguá - Roberto Marinho, Rio De Janeiro, Brasil",
        "city": "Rio De Janeiro",
        "state": "RJ"
      },
      {
        "code": "SBJU",
        "name": "Orlando Bezerra De Menezes",
        "display_name": "Orlando Bezerra De Menezes, Juazeiro Do Norte, Brasil",
        "city": "Juazeiro Do Norte",
        "state": "CE"
      },
      {
        "code": "SBJV",
        "name": "Lauro Carneiro De Loyola",
        "display_name": "Lauro Carneiro De Loyola, Joinville, Brasil",
        "city": "Joinville",
        "state": "SC"
      },
      {
        "code": "SBKG",
        "name": "Presidente João Suassuna",
        "display_name": "Presidente João Suassuna, Campina Grande, Brasil",
        "city": "Campina Grande",
        "state": "PB"
      },
      {
        "code": "SBKP",
        "name": "Viracopos",
        "display_name": "Viracopos, Campinas, Brasil",
        "city": "Campinas",
        "state": "SP"
      },
      {
        "code": "SBLE",
        "name": "Horácio De Mattos",
        "display_name": "Horácio De Mattos, Lençóis, Brasil",
        "city": "Lençóis",
        "state": "BA"
      },
      {
        "code": "SBLJ",
        "name": "Lages",
        "display_name": "Lages, Lages, Brasil",
        "city": "Lages",
        "state": "SC"
      },
      {
        "code": "SBLO",
        "name": "Governador José Richa",
        "display_name": "Governador José Richa, Londrina, Brasil",
        "city": "Londrina",
        "state": "PR"
      },
      {
        "code": "SBLP",
        "name": "Bom Jesus Da Lapa",
        "display_name": "Bom Jesus Da Lapa, Bom Jesus Da Lapa, Brasil",
        "city": "Bom Jesus Da Lapa",
        "state": "BA"
      },
      {
        "code": "SBMA",
        "name": "João Correa Da Rocha",
        "display_name": "João Correa Da Rocha, Marabá, Brasil",
        "city": "Marabá",
        "state": "PA"
      },
      {
        "code": "SBMD",
        "name": "Monte Dourado",
        "display_name": "Monte Dourado, Almeirim, Brasil",
        "city": "Almeirim",
        "state": "PA"
      },
      {
        "code": "SBME",
        "name": "Macaé",
        "display_name": "Macaé, Macaé, Brasil",
        "city": "Macaé",
        "state": "RJ"
      },
      {
        "code": "SBMG",
        "name": "Sílvio Name Júnior",
        "display_name": "Sílvio Name Júnior, Maringá, Brasil",
        "city": "Maringá",
        "state": "PR"
      },
      {
        "code": "SBMK",
        "name": "Mário Ribeiro",
        "display_name": "Mário Ribeiro, Montes Claros, Brasil",
        "city": "Montes Claros",
        "state": "MG"
      },
      {
        "code": "SBML",
        "name": "Frank Miloye Milenkovich",
        "display_name": "Frank Miloye Milenkovich, Marília, Brasil",
        "city": "Marília",
        "state": "SP"
      },
      {
        "code": "SBMO",
        "name": "Zumbi Dos Palmares",
        "display_name": "Zumbi Dos Palmares, Maceió, Brasil",
        "city": "Maceió",
        "state": "AL"
      },
      {
        "code": "SBMQ",
        "name": "Alberto Alcolumbre",
        "display_name": "Alberto Alcolumbre, Macapá, Brasil",
        "city": "Macapá",
        "state": "AP"
      },
      {
        "code": "SBMS",
        "name": "Dix-Sept Rosado",
        "display_name": "Dix-Sept Rosado, Mossoró, Brasil",
        "city": "Mossoró",
        "state": "RN"
      },
      {
        "code": "SBMT",
        "name": "Campo De Marte",
        "display_name": "Campo De Marte, São Paulo, Brasil",
        "city": "São Paulo",
        "state": "SP"
      },
      {
        "code": "SBMY",
        "name": "Manicoré",
        "display_name": "Manicoré, Manicoré, Brasil",
        "city": "Manicoré",
        "state": "AM"
      },
      {
        "code": "SBNF",
        "name": "Ministro Victor Konder",
        "display_name": "Ministro Victor Konder, Navegantes, Brasil",
        "city": "Navegantes",
        "state": "SC"
      },
      {
        "code": "SBNM",
        "name": "Santo Ângelo",
        "display_name": "Santo Ângelo, Santo Ângelo, Brasil",
        "city": "Santo Ângelo",
        "state": "RS"
      },
      {
        "code": "SBOI",
        "name": "Oiapoque",
        "display_name": "Oiapoque, Oiapoque, Brasil",
        "city": "Oiapoque",
        "state": "AP"
      },
      {
        "code": "SBPA",
        "name": "Salgado Filho",
        "display_name": "Salgado Filho, Porto Alegre, Brasil",
        "city": "Porto Alegre",
        "state": "RS"
      },
      {
        "code": "SBPB",
        "name": "Aeroporto Internacional De Parnaíba / Prefeito Doutor João Silva Filho",
        "display_name": "Aeroporto Internacional De Parnaíba / Prefeito Doutor João Silva Filho, Parnaíba, Brasil",
        "city": "Parnaíba",
        "state": "PI"
      },
      {
        "code": "SBPC",
        "name": "Embaixador Walther Moreira Salles",
        "display_name": "Embaixador Walther Moreira Salles, Poços De Caldas, Brasil",
        "city": "Poços De Caldas",
        "state": "MG"
      },
      {
        "code": "SBPF",
        "name": "Lauro Kurtz",
        "display_name": "Lauro Kurtz, Passo Fundo, Brasil",
        "city": "Passo Fundo",
        "state": "RS"
      },
      {
        "code": "SBPJ",
        "name": "Brigadeiro Lysias Rodrigues",
        "display_name": "Brigadeiro Lysias Rodrigues, Palmas, Brasil",
        "city": "Palmas",
        "state": "TO"
      },
      {
        "code": "SBPK",
        "name": "João Simões Lopes Neto",
        "display_name": "João Simões Lopes Neto, Pelotas, Brasil",
        "city": "Pelotas",
        "state": "RS"
      },
      {
        "code": "SBPL",
        "name": "Senador Nilo Coelho",
        "display_name": "Senador Nilo Coelho, Petrolina, Brasil",
        "city": "Petrolina",
        "state": "PE"
      },
      {
        "code": "SDPE",
        "name": "Porto Nacional",
        "display_name": "Porto Nacional, Porto Nacional, Brasil",
        "city": "Porto Nacional",
        "state": "TO"
      },
      {
        "code": "SBPP",
        "name": "Ponta Porã",
        "display_name": "Ponta Porã, Ponta Porã, Brasil",
        "city": "Ponta Porã",
        "state": "MS"
      },
      {
        "code": "SBPR",
        "name": "Carlos Prates",
        "display_name": "Carlos Prates, Belo Horizonte, Brasil",
        "city": "Belo Horizonte",
        "state": "MG"
      },
      {
        "code": "SBPS",
        "name": "Porto Seguro",
        "display_name": "Porto Seguro, Porto Seguro, Brasil",
        "city": "Porto Seguro",
        "state": "BA"
      },
      {
        "code": "SBPV",
        "name": "Governador Jorge Teixeira De Oliveira",
        "display_name": "Governador Jorge Teixeira De Oliveira, Porto Velho, Brasil",
        "city": "Porto Velho",
        "state": "RO"
      },
      {
        "code": "SBRB",
        "name": "Plácido De Castro",
        "display_name": "Plácido De Castro, Rio Branco, Brasil",
        "city": "Rio Branco",
        "state": "AC"
      },
      {
        "code": "SBRD",
        "name": "Rondonópolis - Maestro Marinho Franco",
        "display_name": "Rondonópolis - Maestro Marinho Franco, Rondonópolis, Brasil",
        "city": "Rondonópolis",
        "state": "MT"
      },
      {
        "code": "SBRF",
        "name": "Guararapes - Gilberto Freyre",
        "display_name": "Guararapes - Gilberto Freyre, Recife, Brasil",
        "city": "Recife",
        "state": "PE"
      },
      {
        "code": "SBRJ",
        "name": "Santos Dumont",
        "display_name": "Santos Dumont, Rio De Janeiro, Brasil",
        "city": "Rio De Janeiro",
        "state": "RJ"
      },
      {
        "code": "SBRP",
        "name": "Leite Lopes",
        "display_name": "Leite Lopes, Ribeirão Preto, Brasil",
        "city": "Ribeirão Preto",
        "state": "SP"
      },
      {
        "code": "SBSG",
        "name": "Governador Aluizio Alves",
        "display_name": "Governador Aluizio Alves, São Gonçalo Do Amarante, Brasil",
        "city": "São Gonçalo Do Amarante",
        "state": "RN"
      },
      {
        "code": "SBSJ",
        "name": "Professor Urbano Ernesto Stumpf",
        "display_name": "Professor Urbano Ernesto Stumpf, São José Dos Campos, Brasil",
        "city": "São José Dos Campos",
        "state": "SP"
      },
      {
        "code": "SBSL",
        "name": "Marechal Cunha Machado",
        "display_name": "Marechal Cunha Machado, São Luís, Brasil",
        "city": "São Luís",
        "state": "MA"
      },
      {
        "code": "SBSM",
        "name": "Santa Maria",
        "display_name": "Santa Maria, Santa Maria, Brasil",
        "city": "Santa Maria",
        "state": "RS"
      },
      {
        "code": "SBSN",
        "name": "Maestro Wilson Fonseca",
        "display_name": "Maestro Wilson Fonseca, Santarém, Brasil",
        "city": "Santarém",
        "state": "PA"
      },
      {
        "code": "SBSP",
        "name": "Congonhas",
        "display_name": "Congonhas, São Paulo, Brasil",
        "city": "São Paulo",
        "state": "SP"
      },
      {
        "code": "SBSR",
        "name": "Professor Eriberto Manoel Reino",
        "display_name": "Professor Eriberto Manoel Reino, São José Do Rio Preto, Brasil",
        "city": "São José Do Rio Preto",
        "state": "SP"
      },
      {
        "code": "SBSV",
        "name": "Deputado Luís Eduardo Magalhães",
        "display_name": "Deputado Luís Eduardo Magalhães, Salvador, Brasil",
        "city": "Salvador",
        "state": "BA"
      },
      {
        "code": "SBTB",
        "name": "Trombetas",
        "display_name": "Trombetas, Oriximiná, Brasil",
        "city": "Oriximiná",
        "state": "PA"
      },
      {
        "code": "SBTD",
        "name": "Luiz Dalcanale Filho",
        "display_name": "Luiz Dalcanale Filho, Toledo, Brasil",
        "city": "Toledo",
        "state": "PR"
      },
      {
        "code": "SBTE",
        "name": "Senador Petrônio Portella",
        "display_name": "Senador Petrônio Portella, Teresina, Brasil",
        "city": "Teresina",
        "state": "PI"
      },
      {
        "code": "SBTF",
        "name": "Tefé",
        "display_name": "Tefé, Tefé, Brasil",
        "city": "Tefé",
        "state": "AM"
      },
      {
        "code": "SBTG",
        "name": "Três Lagoas",
        "display_name": "Três Lagoas, Três Lagoas, Brasil",
        "city": "Três Lagoas",
        "state": "MS"
      },
      {
        "code": "SBTK",
        "name": "Tarauacá",
        "display_name": "Tarauacá, Tarauacá, Brasil",
        "city": "Tarauacá",
        "state": "AC"
      },
      {
        "code": "SSTE",
        "name": "Torres",
        "display_name": "Torres, Torres, Brasil",
        "city": "Torres",
        "state": "RS"
      },
      {
        "code": "SBTT",
        "name": "Tabatinga",
        "display_name": "Tabatinga, Tabatinga, Brasil",
        "city": "Tabatinga",
        "state": "AM"
      },
      {
        "code": "SBTU",
        "name": "Tucuruí",
        "display_name": "Tucuruí, Tucuruí, Brasil",
        "city": "Tucuruí",
        "state": "PA"
      },
      {
        "code": "SBUA",
        "name": "São Gabriel Da Cachoeira",
        "display_name": "São Gabriel Da Cachoeira, São Gabriel Da Cachoeira, Brasil",
        "city": "São Gabriel Da Cachoeira",
        "state": "AM"
      },
      {
        "code": "SBUF",
        "name": "Paulo Afonso",
        "display_name": "Paulo Afonso, Paulo Afonso, Brasil",
        "city": "Paulo Afonso",
        "state": "BA"
      },
      {
        "code": "SBUG",
        "name": "Rubem Berta",
        "display_name": "Rubem Berta, Uruguaiana, Brasil",
        "city": "Uruguaiana",
        "state": "RS"
      },
      {
        "code": "SBUL",
        "name": "Ten Cel Aviador César Bombonato",
        "display_name": "Ten Cel Aviador César Bombonato, Uberlândia, Brasil",
        "city": "Uberlândia",
        "state": "MG"
      },
      {
        "code": "SBUR",
        "name": "Mario De Almeida Franco",
        "display_name": "Mario De Almeida Franco, Uberaba, Brasil",
        "city": "Uberaba",
        "state": "MG"
      },
      {
        "code": "SBVG",
        "name": "Major Brigadeiro Trompowsky",
        "display_name": "Major Brigadeiro Trompowsky, Varginha, Brasil",
        "city": "Varginha",
        "state": "MG"
      },
      {
        "code": "SBVH",
        "name": "Vilhena",
        "display_name": "Vilhena, Vilhena, Brasil",
        "city": "Vilhena",
        "state": "RO"
      },
      {
        "code": "SBVT",
        "name": "Eurico De Aguiar Salles",
        "display_name": "Eurico De Aguiar Salles, Vitória, Brasil",
        "city": "Vitória",
        "state": "ES"
      },
      {
        "code": "SBZM",
        "name": "Presidente Itamar Franco",
        "display_name": "Presidente Itamar Franco, Goianá, Brasil",
        "city": "Goianá",
        "state": "MG"
      },
      {
        "code": "SDAA",
        "name": "Araras",
        "display_name": "Araras, Araras, Brasil",
        "city": "Araras",
        "state": "SP"
      },
      {
        "code": "SDAD",
        "name": "Everaldo Moraes Barreto",
        "display_name": "Everaldo Moraes Barreto, Adamantina, Brasil",
        "city": "Adamantina",
        "state": "SP"
      },
      {
        "code": "SDAE",
        "name": "São Pedro",
        "display_name": "São Pedro, São Pedro, Brasil",
        "city": "São Pedro",
        "state": "SP"
      },
      {
        "code": "SDAG",
        "name": "Angra Dos Reis",
        "display_name": "Angra Dos Reis, Angra Dos Reis, Brasil",
        "city": "Angra Dos Reis",
        "state": "RJ"
      },
      {
        "code": "SDAI",
        "name": "Americana",
        "display_name": "Americana, Americana, Brasil",
        "city": "Americana",
        "state": "SP"
      },
      {
        "code": "SDAM",
        "name": "Estadual De Campos Dos Amarais - Prefeito Francisco Amaral",
        "display_name": "Estadual De Campos Dos Amarais - Prefeito Francisco Amaral, Campinas, Brasil",
        "city": "Campinas",
        "state": "SP"
      },
      {
        "code": "SDBA",
        "name": "Batatais",
        "display_name": "Batatais, Batatais, Brasil",
        "city": "Batatais",
        "state": "SP"
      },
      {
        "code": "SDBB",
        "name": "Bebedouro",
        "display_name": "Bebedouro, Bebedouro, Brasil",
        "city": "Bebedouro",
        "state": "SP"
      },
      {
        "code": "SDBK",
        "name": "Tancredo De Almeida Neves",
        "display_name": "Tancredo De Almeida Neves, Botucatu, Brasil",
        "city": "Botucatu",
        "state": "SP"
      },
      {
        "code": "SDBY",
        "name": "Bariri",
        "display_name": "Bariri, Bariri, Brasil",
        "city": "Bariri",
        "state": "SP"
      },
      {
        "code": "SDCA",
        "name": "Capão Bonito",
        "display_name": "Capão Bonito, Capão Bonito, Brasil",
        "city": "Capão Bonito",
        "state": "SP"
      },
      {
        "code": "SDCG",
        "name": "Senadora Eunice Michiles",
        "display_name": "Senadora Eunice Michiles, São Paulo De Olivença, Brasil",
        "city": "São Paulo De Olivença",
        "state": "AM"
      },
      {
        "code": "SDCO",
        "name": "Sorocaba",
        "display_name": "Sorocaba, Sorocaba, Brasil",
        "city": "Sorocaba",
        "state": "SP"
      },
      {
        "code": "SDDN",
        "name": "Paulino Ribeiro De Andrade",
        "display_name": "Paulino Ribeiro De Andrade, Andradina, Brasil",
        "city": "Andradina",
        "state": "SP"
      },
      {
        "code": "SDDR",
        "name": "Dracena",
        "display_name": "Dracena, Dracena, Brasil",
        "city": "Dracena",
        "state": "SP"
      },
      {
        "code": "SDEP",
        "name": "Geraldo Moacir Bordon",
        "display_name": "Geraldo Moacir Bordon, Presidente Epitácio, Brasil",
        "city": "Presidente Epitácio",
        "state": "SP"
      },
      {
        "code": "SDET",
        "name": "Tietê",
        "display_name": "Tietê, Tietê, Brasil",
        "city": "Tietê",
        "state": "SP"
      },
      {
        "code": "SDFD",
        "name": "Coronel Aviador Carlos Orleans Guimarães",
        "display_name": "Coronel Aviador Carlos Orleans Guimarães, Fernandópolis, Brasil",
        "city": "Fernandópolis",
        "state": "SP"
      },
      {
        "code": "SDFX",
        "name": "Casa Nova",
        "display_name": "Casa Nova, Casa Nova, Brasil",
        "city": "Casa Nova",
        "state": "BA"
      },
      {
        "code": "SDGC",
        "name": "Garça",
        "display_name": "Garça, Garça, Brasil",
        "city": "Garça",
        "state": "SP"
      },
      {
        "code": "SDIG",
        "name": "Ibitinga",
        "display_name": "Ibitinga, Ibitinga, Brasil",
        "city": "Ibitinga",
        "state": "SP"
      },
      {
        "code": "SDIM",
        "name": "Dr. Antonio Ribeiro Nogueira Júnior",
        "display_name": "Dr. Antonio Ribeiro Nogueira Júnior, Itanhaém, Brasil",
        "city": "Itanhaém",
        "state": "SP"
      },
      {
        "code": "SDIV",
        "name": "Ituverava",
        "display_name": "Ituverava, Ituverava, Brasil",
        "city": "Ituverava",
        "state": "SP"
      },
      {
        "code": "SDJC",
        "name": "Jaboticabal",
        "display_name": "Jaboticabal, Jaboticabal, Brasil",
        "city": "Jaboticabal",
        "state": "SP"
      },
      {
        "code": "SDJO",
        "name": "São Joaquim Da Barra",
        "display_name": "São Joaquim Da Barra, São Joaquim Da Barra, Brasil",
        "city": "São Joaquim Da Barra",
        "state": "SP"
      },
      {
        "code": "SDJV",
        "name": "Municipal De São João Da Boa Vista",
        "display_name": "Municipal De São João Da Boa Vista, São João Da Boa Vista, Brasil",
        "city": "São João Da Boa Vista",
        "state": "SP"
      },
      {
        "code": "SDKJ",
        "name": "Formosa Do Rio Preto",
        "display_name": "Formosa Do Rio Preto, Formosa Do Rio Preto, Brasil",
        "city": "Formosa Do Rio Preto",
        "state": "BA"
      },
      {
        "code": "SDKK",
        "name": "Mococa",
        "display_name": "Mococa, Mococa, Brasil",
        "city": "Mococa",
        "state": "SP"
      },
      {
        "code": "SDLL",
        "name": "Yolanda Penteado",
        "display_name": "Yolanda Penteado, Leme, Brasil",
        "city": "Leme",
        "state": "SP"
      },
      {
        "code": "SDLP",
        "name": "Municipal José Boso",
        "display_name": "Municipal José Boso, Lençóis Paulista, Brasil",
        "city": "Lençóis Paulista",
        "state": "SP"
      },
      {
        "code": "SDLY",
        "name": "Armando Natali",
        "display_name": "Armando Natali, Matão, Brasil",
        "city": "Matão",
        "state": "SP"
      },
      {
        "code": "SBMI",
        "name": "Maricá",
        "display_name": "Maricá, Maricá, Brasil",
        "city": "Maricá",
        "state": "RJ"
      },
      {
        "code": "SDMH",
        "name": "Mirassol",
        "display_name": "Mirassol, Mirassol, Brasil",
        "city": "Mirassol",
        "state": "SP"
      },
      {
        "code": "SDMJ",
        "name": "Mogi Mirim",
        "display_name": "Mogi Mirim, Mogi Mirim, Brasil",
        "city": "Mogi Mirim",
        "state": "SP"
      },
      {
        "code": "SDMO",
        "name": "Monte Alto",
        "display_name": "Monte Alto, Monte Alto, Brasil",
        "city": "Monte Alto",
        "state": "SP"
      },
      {
        "code": "SDNH",
        "name": "Novo Horizonte",
        "display_name": "Novo Horizonte, Novo Horizonte, Brasil",
        "city": "Novo Horizonte",
        "state": "SP"
      },
      {
        "code": "SDNM",
        "name": "Brigadeiro Eduardo Gomes",
        "display_name": "Brigadeiro Eduardo Gomes, Nova Mutum, Brasil",
        "city": "Nova Mutum",
        "state": "MT"
      },
      {
        "code": "SDNO",
        "name": "Nelson Garófalo",
        "display_name": "Nelson Garófalo, São Manuel, Brasil",
        "city": "São Manuel",
        "state": "SP"
      },
      {
        "code": "SDOU",
        "name": "Jornalista Benedito Pimentel",
        "display_name": "Jornalista Benedito Pimentel, Ourinhos, Brasil",
        "city": "Ourinhos",
        "state": "SP"
      },
      {
        "code": "SDOV",
        "name": "Mozarlândia",
        "display_name": "Mozarlândia, Mozarlândia, Brasil",
        "city": "Mozarlândia",
        "state": "GO"
      },
      {
        "code": "SDOW",
        "name": "Ourilândia Do Norte",
        "display_name": "Ourilândia Do Norte, Ourilândia Do Norte, Brasil",
        "city": "Ourilândia Do Norte",
        "state": "PA"
      },
      {
        "code": "SDPD",
        "name": "Pindamonhangaba",
        "display_name": "Pindamonhangaba, Pindamonhangaba, Brasil",
        "city": "Pindamonhangaba",
        "state": "SP"
      },
      {
        "code": "SDPN",
        "name": "Penápolis",
        "display_name": "Penápolis, Penápolis, Brasil",
        "city": "Penápolis",
        "state": "SP"
      },
      {
        "code": "SDPV",
        "name": "Presidente Venceslau",
        "display_name": "Presidente Venceslau, Presidente Venceslau, Brasil",
        "city": "Presidente Venceslau",
        "state": "SP"
      },
      {
        "code": "SDPW",
        "name": "Pedro Morganti",
        "display_name": "Pedro Morganti, Piracicaba, Brasil",
        "city": "Piracicaba",
        "state": "SP"
      },
      {
        "code": "SDPY",
        "name": "Pirassununga",
        "display_name": "Pirassununga, Pirassununga, Brasil",
        "city": "Pirassununga",
        "state": "SP"
      },
      {
        "code": "SDRK",
        "name": "Rio Claro",
        "display_name": "Rio Claro, Rio Claro, Brasil",
        "city": "Rio Claro",
        "state": "SP"
      },
      {
        "code": "SDRR",
        "name": "Avaré-Arandu",
        "display_name": "Avaré-Arandu, Avaré, Brasil",
        "city": "Avaré",
        "state": "SP"
      },
      {
        "code": "SDRS",
        "name": "Resende",
        "display_name": "Resende, Resende, Brasil",
        "city": "Resende",
        "state": "RJ"
      },
      {
        "code": "SDSC",
        "name": "Mário Pereira Lopes",
        "display_name": "Mário Pereira Lopes, São Carlos, Brasil",
        "city": "São Carlos",
        "state": "SP"
      },
      {
        "code": "SBSO",
        "name": "Regional De Sorriso Adolino Bedin",
        "display_name": "Regional De Sorriso Adolino Bedin, Sorriso, Brasil",
        "city": "Sorriso",
        "state": "MT"
      },
      {
        "code": "SDTB",
        "name": "Atibaia",
        "display_name": "Atibaia, Atibaia, Brasil",
        "city": "Atibaia",
        "state": "SP"
      },
      {
        "code": "SDTF",
        "name": "Tatuí",
        "display_name": "Tatuí, Tatuí, Brasil",
        "city": "Tatuí",
        "state": "SP"
      },
      {
        "code": "SDTI",
        "name": "Tupi Paulista",
        "display_name": "Tupi Paulista, Tupi Paulista, Brasil",
        "city": "Tupi Paulista",
        "state": "SP"
      },
      {
        "code": "SDTK",
        "name": "Paraty",
        "display_name": "Paraty, Paraty, Brasil",
        "city": "Paraty",
        "state": "RJ"
      },
      {
        "code": "SDTP",
        "name": "José Vicente Faria Lima",
        "display_name": "José Vicente Faria Lima, Tupã, Brasil",
        "city": "Tupã",
        "state": "SP"
      },
      {
        "code": "SDUB",
        "name": "Estadual Gastão Madeira",
        "display_name": "Estadual Gastão Madeira, Ubatuba, Brasil",
        "city": "Ubatuba",
        "state": "SP"
      },
      {
        "code": "SDUN",
        "name": "Itaperuna",
        "display_name": "Itaperuna, Itaperuna, Brasil",
        "city": "Itaperuna",
        "state": "RJ"
      },
      {
        "code": "SDUQ",
        "name": "Paraguaçu Paulista",
        "display_name": "Paraguaçu Paulista, Paraguaçu Paulista, Brasil",
        "city": "Paraguaçu Paulista",
        "state": "SP"
      },
      {
        "code": "SDVE",
        "name": "Vera Cruz",
        "display_name": "Vera Cruz, Vera Cruz, Brasil",
        "city": "Vera Cruz",
        "state": "SP"
      },
      {
        "code": "SDVG",
        "name": "Domingos Pignatari",
        "display_name": "Domingos Pignatari, Votuporanga, Brasil",
        "city": "Votuporanga",
        "state": "SP"
      },
      {
        "code": "SDWQ",
        "name": "Alenquer",
        "display_name": "Alenquer, Alenquer, Brasil",
        "city": "Alenquer",
        "state": "PA"
      },
      {
        "code": "SDXF",
        "name": "Alto Paraíso De Goiás",
        "display_name": "Alto Paraíso De Goiás, Alto Paraíso De Goiás, Brasil",
        "city": "Alto Paraíso De Goiás",
        "state": "GO"
      },
      {
        "code": "SDXJ",
        "name": "Costa Rica",
        "display_name": "Costa Rica, Costa Rica, Brasil",
        "city": "Costa Rica",
        "state": "MS"
      },
      {
        "code": "SDZG",
        "name": "Pedro Teixeira Castelo Regional Tauá",
        "display_name": "Pedro Teixeira Castelo Regional Tauá, Tauá, Brasil",
        "city": "Tauá",
        "state": "CE"
      },
      {
        "code": "SIBU",
        "name": "Jerônimo Sérgio Rosado Maia",
        "display_name": "Jerônimo Sérgio Rosado Maia, Catolé Do Rocha, Brasil",
        "city": "Catolé Do Rocha",
        "state": "PB"
      },
      {
        "code": "SIBW",
        "name": "Conceição",
        "display_name": "Conceição, Conceição, Brasil",
        "city": "Conceição",
        "state": "PB"
      },
      {
        "code": "SIBY",
        "name": "Lourival Nunes De Farias",
        "display_name": "Lourival Nunes De Farias, Monteiro, Brasil",
        "city": "Monteiro",
        "state": "PB"
      },
      {
        "code": "SIBZ",
        "name": "Itaporanga",
        "display_name": "Itaporanga, Itaporanga, Brasil",
        "city": "Itaporanga",
        "state": "PB"
      },
      {
        "code": "SICB",
        "name": "Pedro Simões Pimenta",
        "display_name": "Pedro Simões Pimenta, Cuité, Brasil",
        "city": "Cuité",
        "state": "PB"
      },
      {
        "code": "SILC",
        "name": "Aeroporto Municipal Bom Futuro",
        "display_name": "Aeroporto Municipal Bom Futuro, Lucas Do Rio Verde, Brasil",
        "city": "Lucas Do Rio Verde",
        "state": "MT"
      },
      {
        "code": "SIMK",
        "name": "Tenente Lund Pressoto",
        "display_name": "Tenente Lund Pressoto, Franca, Brasil",
        "city": "Franca",
        "state": "SP"
      },
      {
        "code": "SIZX",
        "name": "Juara Sul",
        "display_name": "Juara Sul, Juara, Brasil",
        "city": "Juara",
        "state": "MT"
      },
      {
        "code": "SJAU",
        "name": "Araguacema",
        "display_name": "Araguacema, Araguacema, Brasil",
        "city": "Araguacema",
        "state": "TO"
      },
      {
        "code": "SJBY",
        "name": "João Silva",
        "display_name": "João Silva, Santa Inês, Brasil",
        "city": "Santa Inês",
        "state": "MA"
      },
      {
        "code": "SJGU",
        "name": "Araguatins",
        "display_name": "Araguatins, Araguatins, Brasil",
        "city": "Araguatins",
        "state": "TO"
      },
      {
        "code": "SBJA",
        "name": "Regional Sul",
        "display_name": "Regional Sul, Jaguaruna, Brasil",
        "city": "Jaguaruna",
        "state": "SC"
      },
      {
        "code": "SJOG",
        "name": "Ariquemes",
        "display_name": "Ariquemes, Ariquemes, Brasil",
        "city": "Ariquemes",
        "state": "RO"
      },
      {
        "code": "SJQN",
        "name": "Quirinópolis",
        "display_name": "Quirinópolis, Quirinópolis, Brasil",
        "city": "Quirinópolis",
        "state": "GO"
      },
      {
        "code": "SJRG",
        "name": "Regional De Rio Grande",
        "display_name": "Regional De Rio Grande, Rio Grande, Brasil",
        "city": "Rio Grande",
        "state": "RS"
      },
      {
        "code": "SJTS",
        "name": "Terra Santa",
        "display_name": "Terra Santa, Terra Santa, Brasil",
        "city": "Terra Santa",
        "state": "PA"
      },
      {
        "code": "SJVO",
        "name": "Aragarças",
        "display_name": "Aragarças, Aragarças, Brasil",
        "city": "Aragarças",
        "state": "GO"
      },
      {
        "code": "SJZA",
        "name": "Pedro Vieira Moreira",
        "display_name": "Pedro Vieira Moreira, Cajazeiras, Brasil",
        "city": "Cajazeiras",
        "state": "PB"
      },
      {
        "code": "SNAB",
        "name": "Araripina",
        "display_name": "Araripina, Araripina, Brasil",
        "city": "Araripina",
        "state": "PE"
      },
      {
        "code": "SNAE",
        "name": "Arcoverde",
        "display_name": "Arcoverde, Arcoverde, Brasil",
        "city": "Arcoverde",
        "state": "PE"
      },
      {
        "code": "SNAG",
        "name": "Araguari",
        "display_name": "Araguari, Araguari, Brasil",
        "city": "Araguari",
        "state": "MG"
      },
      {
        "code": "SNAL",
        "name": "Arapiraca",
        "display_name": "Arapiraca, Arapiraca, Brasil",
        "city": "Arapiraca",
        "state": "AL"
      },
      {
        "code": "SNAP",
        "name": "Janaúba",
        "display_name": "Janaúba, Janaúba, Brasil",
        "city": "Janaúba",
        "state": "MG"
      },
      {
        "code": "SNAR",
        "name": "Cirilo Queiróz",
        "display_name": "Cirilo Queiróz, Almenara, Brasil",
        "city": "Almenara",
        "state": "MG"
      },
      {
        "code": "SNAS",
        "name": "Três Marias",
        "display_name": "Três Marias, Três Marias, Brasil",
        "city": "Três Marias",
        "state": "MG"
      },
      {
        "code": "SBAC",
        "name": "Aeroporto Regional De Canoa Quebrada Dragão Do Mar",
        "display_name": "Aeroporto Regional De Canoa Quebrada Dragão Do Mar, Aracati, Brasil",
        "city": "Aracati",
        "state": "CE"
      },
      {
        "code": "SNAX",
        "name": "Marcelo Pires Halzhausen",
        "display_name": "Marcelo Pires Halzhausen, Assis, Brasil",
        "city": "Assis",
        "state": "SP"
      },
      {
        "code": "SNBA",
        "name": "Chafei Amsei (Sbbt*)",
        "display_name": "Chafei Amsei (Sbbt*), Barretos, Brasil",
        "city": "Barretos",
        "state": "SP"
      },
      {
        "code": "SNBC",
        "name": "Barra Do Corda",
        "display_name": "Barra Do Corda, Barra Do Corda, Brasil",
        "city": "Barra Do Corda",
        "state": "MA"
      },
      {
        "code": "SNBG",
        "name": "Baixo Guandu - Aimorés",
        "display_name": "Baixo Guandu - Aimorés, Baixo Guandu, Brasil",
        "city": "Baixo Guandu",
        "state": "ES"
      },
      {
        "code": "SNBI",
        "name": "Bacabal",
        "display_name": "Bacabal, Bacabal, Brasil",
        "city": "Bacabal",
        "state": "MA"
      },
      {
        "code": "SNBM",
        "name": "Cristiano Ferreira Varella",
        "display_name": "Cristiano Ferreira Varella, Muriaé, Brasil",
        "city": "Muriaé",
        "state": "MG"
      },
      {
        "code": "SNBR",
        "name": "Dom Ricardo Weberberger",
        "display_name": "Dom Ricardo Weberberger, Barreiras, Brasil",
        "city": "Barreiras",
        "state": "BA"
      },
      {
        "code": "SNBS",
        "name": "Balsas",
        "display_name": "Balsas, Balsas, Brasil",
        "city": "Balsas",
        "state": "MA"
      },
      {
        "code": "SNBX",
        "name": "Barra",
        "display_name": "Barra, Barra, Brasil",
        "city": "Barra",
        "state": "BA"
      },
      {
        "code": "SNBZ",
        "name": "Paramirim",
        "display_name": "Paramirim, Paramirim, Brasil",
        "city": "Paramirim",
        "state": "BA"
      },
      {
        "code": "SNCA",
        "name": "Campo Belo",
        "display_name": "Campo Belo, Campo Belo, Brasil",
        "city": "Campo Belo",
        "state": "MG"
      },
      {
        "code": "SNCS",
        "name": "Campos Sales",
        "display_name": "Campos Sales, Campos Sales, Brasil",
        "city": "Campos Sales",
        "state": "CE"
      },
      {
        "code": "SNCT",
        "name": "Caratinga",
        "display_name": "Caratinga, Ubaporanga, Brasil",
        "city": "Ubaporanga",
        "state": "MG"
      },
      {
        "code": "SNCX",
        "name": "Colatina",
        "display_name": "Colatina, Colatina, Brasil",
        "city": "Colatina",
        "state": "ES"
      },
      {
        "code": "SNCZ",
        "name": "Ponte Nova",
        "display_name": "Ponte Nova, Ponte Nova, Brasil",
        "city": "Ponte Nova",
        "state": "MG"
      },
      {
        "code": "SNDC",
        "name": "Redenção",
        "display_name": "Redenção, Redenção, Brasil",
        "city": "Redenção",
        "state": "PA"
      },
      {
        "code": "SNDT",
        "name": "Juscelino Kubitschek",
        "display_name": "Juscelino Kubitschek, Diamantina, Brasil",
        "city": "Diamantina",
        "state": "MG"
      },
      {
        "code": "SNDV",
        "name": "Brigadeiro Antônio Cabral",
        "display_name": "Brigadeiro Antônio Cabral, Divinópolis, Brasil",
        "city": "Divinópolis",
        "state": "MG"
      },
      {
        "code": "SNEB",
        "name": "Nagib Demachki",
        "display_name": "Nagib Demachki, Paragominas, Brasil",
        "city": "Paragominas",
        "state": "PA"
      },
      {
        "code": "SNED",
        "name": "Sócrates Rezende",
        "display_name": "Sócrates Rezende, Canavieiras, Brasil",
        "city": "Canavieiras",
        "state": "BA"
      },
      {
        "code": "SNEE",
        "name": "Vacaria Novo",
        "display_name": "Vacaria Novo, Vacaria, Brasil",
        "city": "Vacaria",
        "state": "RS"
      },
      {
        "code": "SNFE",
        "name": "Comandante Paschoal Patrocínio Filho",
        "display_name": "Comandante Paschoal Patrocínio Filho, Alfenas, Brasil",
        "city": "Alfenas",
        "state": "MG"
      },
      {
        "code": "SNFO",
        "name": "Formiga",
        "display_name": "Formiga, Formiga, Brasil",
        "city": "Formiga",
        "state": "MG"
      },
      {
        "code": "SNFU",
        "name": "Frutal",
        "display_name": "Frutal, Frutal, Brasil",
        "city": "Frutal",
        "state": "MG"
      },
      {
        "code": "SNFX",
        "name": "São Félix Do Xingu",
        "display_name": "São Félix Do Xingu, São Félix Do Xingu, Brasil",
        "city": "São Félix Do Xingu",
        "state": "PA"
      },
      {
        "code": "SNGA",
        "name": "Guarapari",
        "display_name": "Guarapari, Guarapari, Brasil",
        "city": "Guarapari",
        "state": "ES"
      },
      {
        "code": "SNGB",
        "name": "Gilbués",
        "display_name": "Gilbués, Gilbués, Brasil",
        "city": "Gilbués",
        "state": "PI"
      },
      {
        "code": "SNGD",
        "name": "Guadalupe",
        "display_name": "Guadalupe, Guadalupe, Brasil",
        "city": "Guadalupe",
        "state": "PI"
      },
      {
        "code": "SNGG",
        "name": "Bom Jesus Do Gurguéia",
        "display_name": "Bom Jesus Do Gurguéia, Bom Jesus, Brasil",
        "city": "Bom Jesus",
        "state": "PI"
      },
      {
        "code": "SNGI",
        "name": "Guanambi",
        "display_name": "Guanambi, Guanambi, Brasil",
        "city": "Guanambi",
        "state": "BA"
      },
      {
        "code": "SNGN",
        "name": "Garanhuns",
        "display_name": "Garanhuns, Garanhuns, Brasil",
        "city": "Garanhuns",
        "state": "PE"
      },
      {
        "code": "SNGX",
        "name": "Guaxupé",
        "display_name": "Guaxupé, Guaxupé, Brasil",
        "city": "Guaxupé",
        "state": "MG"
      },
      {
        "code": "SNHS",
        "name": "Santa Magalhães",
        "display_name": "Santa Magalhães, Serra Talhada, Brasil",
        "city": "Serra Talhada",
        "state": "PE"
      },
      {
        "code": "SNIB",
        "name": "Itaberaba",
        "display_name": "Itaberaba, Itaberaba, Brasil",
        "city": "Itaberaba",
        "state": "BA"
      },
      {
        "code": "SNIC",
        "name": "Irecê",
        "display_name": "Irecê, Irecê, Brasil",
        "city": "Irecê",
        "state": "BA"
      },
      {
        "code": "SNIE",
        "name": "Caetité",
        "display_name": "Caetité, Caetité, Brasil",
        "city": "Caetité",
        "state": "BA"
      },
      {
        "code": "SNIG",
        "name": "Iguatu",
        "display_name": "Iguatu, Iguatu, Brasil",
        "city": "Iguatu",
        "state": "CE"
      },
      {
        "code": "SNIO",
        "name": "Cipó",
        "display_name": "Cipó, Cipó, Brasil",
        "city": "Cipó",
        "state": "BA"
      },
      {
        "code": "SNIP",
        "name": "Itapetinga",
        "display_name": "Itapetinga, Itapetinga, Brasil",
        "city": "Itapetinga",
        "state": "BA"
      },
      {
        "code": "SNIT",
        "name": "Ibotirama",
        "display_name": "Ibotirama, Ibotirama, Brasil",
        "city": "Ibotirama",
        "state": "BA"
      },
      {
        "code": "SNIU",
        "name": "Ipiaú",
        "display_name": "Ipiaú, Ipiaú, Brasil",
        "city": "Ipiaú",
        "state": "BA"
      },
      {
        "code": "SNJB",
        "name": "Jacobina",
        "display_name": "Jacobina, Jacobina, Brasil",
        "city": "Jacobina",
        "state": "BA"
      },
      {
        "code": "SDIY",
        "name": "João Durval Carneiro (Sbfe*)",
        "display_name": "João Durval Carneiro (Sbfe*), Feira De Santana, Brasil",
        "city": "Feira De Santana",
        "state": "BA"
      },
      {
        "code": "SNJK",
        "name": "Jequié",
        "display_name": "Jequié, Jequié, Brasil",
        "city": "Jequié",
        "state": "BA"
      },
      {
        "code": "SNJM",
        "name": "Elias Breder",
        "display_name": "Elias Breder, Manhuaçu, Brasil",
        "city": "Manhuaçu",
        "state": "MG"
      },
      {
        "code": "SNJO",
        "name": "Aeroclube De João Pessoa",
        "display_name": "Aeroclube De João Pessoa, João Pessoa, Brasil",
        "city": "João Pessoa",
        "state": "PB"
      },
      {
        "code": "SNJP",
        "name": "João Pinheiro",
        "display_name": "João Pinheiro, João Pinheiro, Brasil",
        "city": "João Pinheiro",
        "state": "MG"
      },
      {
        "code": "SNJQ",
        "name": "Jequitinhonha",
        "display_name": "Jequitinhonha, Jequitinhonha, Brasil",
        "city": "Jequitinhonha",
        "state": "MG"
      },
      {
        "code": "SNJR",
        "name": "Prefeito Octávio De Almeida Neves",
        "display_name": "Prefeito Octávio De Almeida Neves, São João Del Rei, Brasil",
        "city": "São João Del Rei",
        "state": "MG"
      },
      {
        "code": "SNKD",
        "name": "Conceição Do Mato Dentro",
        "display_name": "Conceição Do Mato Dentro, Conceição Do Mato Dentro, Brasil",
        "city": "Conceição Do Mato Dentro",
        "state": "MG"
      },
      {
        "code": "SNKF",
        "name": "Das Bandeirinhas",
        "display_name": "Das Bandeirinhas, Conselheiro Lafaiete, Brasil",
        "city": "Conselheiro Lafaiete",
        "state": "MG"
      },
      {
        "code": "SNKI",
        "name": "Cachoeiro De Itapemirim",
        "display_name": "Cachoeiro De Itapemirim, Cachoeiro De Itapemirim, Brasil",
        "city": "Cachoeiro De Itapemirim",
        "state": "ES"
      },
      {
        "code": "SNKK",
        "name": "Caicó",
        "display_name": "Caicó, Caicó, Brasil",
        "city": "Caicó",
        "state": "RN"
      },
      {
        "code": "SNKN",
        "name": "Currais Novos",
        "display_name": "Currais Novos, Currais Novos, Brasil",
        "city": "Currais Novos",
        "state": "RN"
      },
      {
        "code": "SNKR",
        "name": "Corrente",
        "display_name": "Corrente, Corrente, Brasil",
        "city": "Corrente",
        "state": "PI"
      },
      {
        "code": "SNLI",
        "name": "Abaeté",
        "display_name": "Abaeté, Abaeté, Brasil",
        "city": "Abaeté",
        "state": "MG"
      },
      {
        "code": "SNLN",
        "name": "Municipal De Linhares",
        "display_name": "Municipal De Linhares, Linhares, Brasil",
        "city": "Linhares",
        "state": "ES"
      },
      {
        "code": "SNLO",
        "name": "São Lourenço",
        "display_name": "São Lourenço, São Lourenço, Brasil",
        "city": "São Lourenço",
        "state": "MG"
      },
      {
        "code": "SNLT",
        "name": "Paulistana",
        "display_name": "Paulistana, Paulistana, Brasil",
        "city": "Paulistana",
        "state": "PI"
      },
      {
        "code": "SNMA",
        "name": "Monte Alegre",
        "display_name": "Monte Alegre, Monte Alegre, Brasil",
        "city": "Monte Alegre",
        "state": "PA"
      },
      {
        "code": "SNMH",
        "name": "Pirenópolis",
        "display_name": "Pirenópolis, Pirenópolis, Brasil",
        "city": "Pirenópolis",
        "state": "GO"
      },
      {
        "code": "SNMJ",
        "name": "Maracás",
        "display_name": "Maracás, Maracás, Brasil",
        "city": "Maracás",
        "state": "BA"
      },
      {
        "code": "SNMK",
        "name": "Mocambinho",
        "display_name": "Mocambinho, Jaíba, Brasil",
        "city": "Jaíba",
        "state": "MG"
      },
      {
        "code": "SNMN",
        "name": "Minas Novas",
        "display_name": "Minas Novas, Minas Novas, Brasil",
        "city": "Minas Novas",
        "state": "MG"
      },
      {
        "code": "SNMR",
        "name": "Maraú",
        "display_name": "Maraú, Maraú, Brasil",
        "city": "Maraú",
        "state": "BA"
      },
      {
        "code": "SNMX",
        "name": "São Mateus",
        "display_name": "São Mateus, São Mateus, Brasil",
        "city": "São Mateus",
        "state": "ES"
      },
      {
        "code": "SNMZ",
        "name": "Porto De Moz",
        "display_name": "Porto De Moz, Porto De Moz, Brasil",
        "city": "Porto De Moz",
        "state": "PA"
      },
      {
        "code": "SNNE",
        "name": "Sebastião Carlos Leite",
        "display_name": "Sebastião Carlos Leite, São João Nepomuceno, Brasil",
        "city": "São João Nepomuceno",
        "state": "MG"
      },
      {
        "code": "SNNU",
        "name": "Nanuque",
        "display_name": "Nanuque, Nanuque, Brasil",
        "city": "Nanuque",
        "state": "MG"
      },
      {
        "code": "SNOB",
        "name": "Aeródromo Público De Sobral",
        "display_name": "Aeródromo Público De Sobral, Sobral, Brasil",
        "city": "Sobral",
        "state": "CE"
      },
      {
        "code": "SNOE",
        "name": "Oeiras",
        "display_name": "Oeiras, Oeiras, Brasil",
        "city": "Oeiras",
        "state": "PI"
      },
      {
        "code": "SNOS",
        "name": "Municipal José Figueiredo",
        "display_name": "Municipal José Figueiredo, Passos, Brasil",
        "city": "Passos",
        "state": "MG"
      },
      {
        "code": "SNOX",
        "name": "Oriximiná",
        "display_name": "Oriximiná, Oriximiná, Brasil",
        "city": "Oriximiná",
        "state": "PA"
      },
      {
        "code": "SNPA",
        "name": "Pará De Minas",
        "display_name": "Pará De Minas, Pará De Minas, Brasil",
        "city": "Pará De Minas",
        "state": "MG"
      },
      {
        "code": "SNPC",
        "name": "Picos",
        "display_name": "Picos, Picos, Brasil",
        "city": "Picos",
        "state": "PI"
      },
      {
        "code": "SNPD",
        "name": "Patos De Minas",
        "display_name": "Patos De Minas, Patos De Minas, Brasil",
        "city": "Patos De Minas",
        "state": "MG"
      },
      {
        "code": "SNPJ",
        "name": "Patrocínio",
        "display_name": "Patrocínio, Patrocínio, Brasil",
        "city": "Patrocínio",
        "state": "MG"
      },
      {
        "code": "SNPQ",
        "name": "Pesqueira",
        "display_name": "Pesqueira, Pesqueira, Brasil",
        "city": "Pesqueira",
        "state": "PE"
      },
      {
        "code": "SBCZ",
        "name": "Cruzeiro Do Sul",
        "display_name": "Cruzeiro Do Sul, Cruzeiro Do Sul, Brasil",
        "city": "Cruzeiro Do Sul",
        "state": "AC"
      },
      {
        "code": "SNPX",
        "name": "Pirapora",
        "display_name": "Pirapora, Pirapora, Brasil",
        "city": "Pirapora",
        "state": "MG"
      },
      {
        "code": "SNPY",
        "name": "São Sebastião Do Paraíso",
        "display_name": "São Sebastião Do Paraíso, São Sebastião Do Paraíso, Brasil",
        "city": "São Sebastião Do Paraíso",
        "state": "MG"
      },
      {
        "code": "SNQD",
        "name": "Sousa",
        "display_name": "Sousa, Sousa, Brasil",
        "city": "Sousa",
        "state": "PB"
      },
      {
        "code": "SNQG",
        "name": "Cangapara",
        "display_name": "Cangapara, Floriano, Brasil",
        "city": "Floriano",
        "state": "PI"
      },
      {
        "code": "SNQU",
        "name": "Mucugê",
        "display_name": "Mucugê, Mucugê, Brasil",
        "city": "Mucugê",
        "state": "BA"
      },
      {
        "code": "SNQV",
        "name": "Curvelo",
        "display_name": "Curvelo, Curvelo, Brasil",
        "city": "Curvelo",
        "state": "MG"
      },
      {
        "code": "SNQX",
        "name": "Quixadá",
        "display_name": "Quixadá, Quixadá, Brasil",
        "city": "Quixadá",
        "state": "CE"
      },
      {
        "code": "SNRD",
        "name": "Prado",
        "display_name": "Prado, Prado, Brasil",
        "city": "Prado",
        "state": "BA"
      },
      {
        "code": "SNRM",
        "name": "Remanso",
        "display_name": "Remanso, Remanso, Brasil",
        "city": "Remanso",
        "state": "BA"
      },
      {
        "code": "SNRP",
        "name": "Rio Paranaíba",
        "display_name": "Rio Paranaíba, Rio Paranaíba, Brasil",
        "city": "Rio Paranaíba",
        "state": "MG"
      },
      {
        "code": "SNRU",
        "name": "Caruaru",
        "display_name": "Caruaru, Caruaru, Brasil",
        "city": "Caruaru",
        "state": "PE"
      },
      {
        "code": "SNRX",
        "name": "Riachão",
        "display_name": "Riachão, Riachão, Brasil",
        "city": "Riachão",
        "state": "MA"
      },
      {
        "code": "SNRZ",
        "name": "Oliveira",
        "display_name": "Oliveira, Oliveira, Brasil",
        "city": "Oliveira",
        "state": "MG"
      },
      {
        "code": "SNSE",
        "name": "Sento Sé",
        "display_name": "Sento Sé, Sento Sé, Brasil",
        "city": "Sento Sé",
        "state": "BA"
      },
      {
        "code": "SNSG",
        "name": "Salgueiro",
        "display_name": "Salgueiro, Salgueiro, Brasil",
        "city": "Salgueiro",
        "state": "PE"
      },
      {
        "code": "SNSI",
        "name": "Santa Maria Do Suaçuí",
        "display_name": "Santa Maria Do Suaçuí, Santa Maria Do Suaçuí, Brasil",
        "city": "Santa Maria Do Suaçuí",
        "state": "MG"
      },
      {
        "code": "SNSM",
        "name": "Salinópolis",
        "display_name": "Salinópolis, Salinópolis, Brasil",
        "city": "Salinópolis",
        "state": "PA"
      },
      {
        "code": "SNSS",
        "name": "Salinas",
        "display_name": "Salinas, Salinas, Brasil",
        "city": "Salinas",
        "state": "MG"
      },
      {
        "code": "SNTF",
        "name": "Teixeira De Freitas",
        "display_name": "Teixeira De Freitas, Teixeira De Freitas, Brasil",
        "city": "Teixeira De Freitas",
        "state": "BA"
      },
      {
        "code": "SNTI",
        "name": "Óbidos",
        "display_name": "Óbidos, Óbidos, Brasil",
        "city": "Óbidos",
        "state": "PA"
      },
      {
        "code": "SNTM",
        "name": "Turmalina",
        "display_name": "Turmalina, Turmalina, Brasil",
        "city": "Turmalina",
        "state": "MG"
      },
      {
        "code": "SNTO",
        "name": "Juscelino Kubitscheck",
        "display_name": "Juscelino Kubitscheck, Teófilo Otoni, Brasil",
        "city": "Teófilo Otoni",
        "state": "MG"
      },
      {
        "code": "SNTQ",
        "name": "Buritirama",
        "display_name": "Buritirama, Buritirama, Brasil",
        "city": "Buritirama",
        "state": "BA"
      },
      {
        "code": "SNTS",
        "name": "Brigadeiro Firmino Ayres",
        "display_name": "Brigadeiro Firmino Ayres, Patos, Brasil",
        "city": "Patos",
        "state": "PB"
      },
      {
        "code": "SNUB",
        "name": "Ubá",
        "display_name": "Ubá, Ubá, Brasil",
        "city": "Ubá",
        "state": "MG"
      },
      {
        "code": "SNUC",
        "name": "Açu",
        "display_name": "Açu, Açu, Brasil",
        "city": "Açu",
        "state": "RN"
      },
      {
        "code": "SNUH",
        "name": "Piumhi",
        "display_name": "Piumhi, Piumhi, Brasil",
        "city": "Piumhi",
        "state": "MG"
      },
      {
        "code": "SNUI",
        "name": "Araçuaí",
        "display_name": "Araçuaí, Araçuaí, Brasil",
        "city": "Araçuaí",
        "state": "MG"
      },
      {
        "code": "SNUN",
        "name": "Unaí",
        "display_name": "Unaí, Unaí, Brasil",
        "city": "Unaí",
        "state": "MG"
      },
      {
        "code": "SNVB",
        "name": "Valença",
        "display_name": "Valença, Valença, Brasil",
        "city": "Valença",
        "state": "BA"
      },
      {
        "code": "SNVC",
        "name": "Viçosa",
        "display_name": "Viçosa, Viçosa, Brasil",
        "city": "Viçosa",
        "state": "MG"
      },
      {
        "code": "SNVD",
        "name": "Santa Maria Da Vitória",
        "display_name": "Santa Maria Da Vitória, Santa Maria Da Vitória, Brasil",
        "city": "Santa Maria Da Vitória",
        "state": "BA"
      },
      {
        "code": "SNVI",
        "name": "Mello Viana",
        "display_name": "Mello Viana, Três Corações, Brasil",
        "city": "Três Corações",
        "state": "MG"
      },
      {
        "code": "SNVS",
        "name": "Breves",
        "display_name": "Breves, Breves, Brasil",
        "city": "Breves",
        "state": "PA"
      },
      {
        "code": "SNWC",
        "name": "Camocim",
        "display_name": "Camocim, Camocim, Brasil",
        "city": "Camocim",
        "state": "CE"
      },
      {
        "code": "SNWS",
        "name": "Crateús",
        "display_name": "Crateús, Crateús, Brasil",
        "city": "Crateús",
        "state": "CE"
      },
      {
        "code": "SNXB",
        "name": "Caxambu",
        "display_name": "Caxambu, Caxambu, Brasil",
        "city": "Caxambu",
        "state": "MG"
      },
      {
        "code": "SNXQ",
        "name": "Xique-Xique",
        "display_name": "Xique-Xique, Xique-Xique, Brasil",
        "city": "Xique-Xique",
        "state": "BA"
      },
      {
        "code": "SNYA",
        "name": "Almeirim",
        "display_name": "Almeirim, Almeirim, Brasil",
        "city": "Almeirim",
        "state": "PA"
      },
      {
        "code": "SNYB",
        "name": "Tito Teixeira",
        "display_name": "Tito Teixeira, Ituiutaba, Brasil",
        "city": "Ituiutaba",
        "state": "MG"
      },
      {
        "code": "SNYE",
        "name": "Pinheiro",
        "display_name": "Pinheiro, Pinheiro, Brasil",
        "city": "Pinheiro",
        "state": "MA"
      },
      {
        "code": "SNYU",
        "name": "Iturama",
        "display_name": "Iturama, Iturama, Brasil",
        "city": "Iturama",
        "state": "MG"
      },
      {
        "code": "SNZA",
        "name": "Pouso Alegre",
        "display_name": "Pouso Alegre, Pouso Alegre, Brasil",
        "city": "Pouso Alegre",
        "state": "MG"
      },
      {
        "code": "SNZR",
        "name": "Pedro Rabelo De Souza",
        "display_name": "Pedro Rabelo De Souza, Paracatu, Brasil",
        "city": "Paracatu",
        "state": "MG"
      },
      {
        "code": "SSAB",
        "name": "Moisés Lupion",
        "display_name": "Moisés Lupion, Ibaiti, Brasil",
        "city": "Ibaiti",
        "state": "PR"
      },
      {
        "code": "SSAE",
        "name": "Arroio Grande",
        "display_name": "Arroio Grande, Arroio Grande, Brasil",
        "city": "Arroio Grande",
        "state": "RS"
      },
      {
        "code": "SSAK",
        "name": "Carlos Ruhl",
        "display_name": "Carlos Ruhl, Cruz Alta, Brasil",
        "city": "Cruz Alta",
        "state": "RS"
      },
      {
        "code": "SSAN",
        "name": "João Galdino",
        "display_name": "João Galdino, Andirá, Brasil",
        "city": "Andirá",
        "state": "PR"
      },
      {
        "code": "SSAP",
        "name": "Capitão João Busse",
        "display_name": "Capitão João Busse, Apucarana, Brasil",
        "city": "Apucarana",
        "state": "PR"
      },
      {
        "code": "SSAQ",
        "name": "Aeroclube De Passo Fundo",
        "display_name": "Aeroclube De Passo Fundo, Passo Fundo, Brasil",
        "city": "Passo Fundo",
        "state": "RS"
      },
      {
        "code": "SSBL",
        "name": "Blumenau",
        "display_name": "Blumenau, Blumenau, Brasil",
        "city": "Blumenau",
        "state": "SC"
      },
      {
        "code": "SSBN",
        "name": "Belém Novo",
        "display_name": "Belém Novo, Porto Alegre, Brasil",
        "city": "Porto Alegre",
        "state": "RS"
      },
      {
        "code": "SSBV",
        "name": "Bela Vista",
        "display_name": "Bela Vista, Bela Vista, Brasil",
        "city": "Bela Vista",
        "state": "MS"
      },
      {
        "code": "SSCB",
        "name": "Municipal De Casa Branca",
        "display_name": "Municipal De Casa Branca, Casa Branca, Brasil",
        "city": "Casa Branca",
        "state": "SP"
      },
      {
        "code": "SSCD",
        "name": "Chapadão Do Sul",
        "display_name": "Chapadão Do Sul, Chapadão Do Sul, Brasil",
        "city": "Chapadão Do Sul",
        "state": "MS"
      },
      {
        "code": "SSCI",
        "name": "Coxim",
        "display_name": "Coxim, Coxim, Brasil",
        "city": "Coxim",
        "state": "MS"
      },
      {
        "code": "SSCK",
        "name": "Olavo Cecco Rigon",
        "display_name": "Olavo Cecco Rigon, Concórdia, Brasil",
        "city": "Concórdia",
        "state": "SC"
      },
      {
        "code": "SNPU",
        "name": "Comandante Virgilio Borim",
        "display_name": "Comandante Virgilio Borim, Paraguaçu, Brasil",
        "city": "Paraguaçu",
        "state": "MG"
      },
      {
        "code": "SSCN",
        "name": "Canela",
        "display_name": "Canela, Canela, Brasil",
        "city": "Canela",
        "state": "RS"
      },
      {
        "code": "SSCP",
        "name": "Francisco Lacerda Júnior",
        "display_name": "Francisco Lacerda Júnior, Cornélio Procópio, Brasil",
        "city": "Cornélio Procópio",
        "state": "PR"
      },
      {
        "code": "SSCR",
        "name": "Ruben Berta",
        "display_name": "Ruben Berta, Marechal Cândido Rondon, Brasil",
        "city": "Marechal Cândido Rondon",
        "state": "PR"
      },
      {
        "code": "SSCT",
        "name": "Engenheiro Gastão De Mesquita Filho",
        "display_name": "Engenheiro Gastão De Mesquita Filho, Cianorte, Brasil",
        "city": "Cianorte",
        "state": "PR"
      },
      {
        "code": "SSEE",
        "name": "Regional Do Vale Do Taquari",
        "display_name": "Regional Do Vale Do Taquari, Estrela, Brasil",
        "city": "Estrela",
        "state": "RS"
      },
      {
        "code": "SSEP",
        "name": "São Sepé",
        "display_name": "São Sepé, São Sepé, Brasil",
        "city": "São Sepé",
        "state": "RS"
      },
      {
        "code": "SSER",
        "name": "Erechim",
        "display_name": "Erechim, Erechim, Brasil",
        "city": "Erechim",
        "state": "RS"
      },
      {
        "code": "SSEZ",
        "name": "Espumoso",
        "display_name": "Espumoso, Espumoso, Brasil",
        "city": "Espumoso",
        "state": "RS"
      },
      {
        "code": "SSFB",
        "name": "Paulo Abdala",
        "display_name": "Paulo Abdala, Francisco Beltrão, Brasil",
        "city": "Francisco Beltrão",
        "state": "PR"
      },
      {
        "code": "SSFL",
        "name": "João Pereira Dos Santos Filho",
        "display_name": "João Pereira Dos Santos Filho, Fronteiras, Brasil",
        "city": "Fronteiras",
        "state": "PI"
      },
      {
        "code": "SSGA",
        "name": "Garibaldi",
        "display_name": "Garibaldi, Garibaldi, Brasil",
        "city": "Garibaldi",
        "state": "RS"
      },
      {
        "code": "SSGB",
        "name": "Municipal De Guaratuba",
        "display_name": "Municipal De Guaratuba, Guaratuba, Brasil",
        "city": "Guaratuba",
        "state": "PR"
      },
      {
        "code": "SSGW",
        "name": "Manoel Ribas",
        "display_name": "Manoel Ribas, Goioerê, Brasil",
        "city": "Goioerê",
        "state": "PR"
      },
      {
        "code": "SSGY",
        "name": "Guaíra",
        "display_name": "Guaíra, Guaíra, Brasil",
        "city": "Guaíra",
        "state": "PR"
      },
      {
        "code": "SSHZ",
        "name": "Walter Bündchen",
        "display_name": "Walter Bündchen, Horizontina, Brasil",
        "city": "Horizontina",
        "state": "RS"
      },
      {
        "code": "SSIE",
        "name": "Teruel Ipanema Estância",
        "display_name": "Teruel Ipanema Estância, Campo Grande, Brasil",
        "city": "Campo Grande",
        "state": "MS"
      },
      {
        "code": "SSIJ",
        "name": "Ijuí",
        "display_name": "Ijuí, Ijuí, Brasil",
        "city": "Ijuí",
        "state": "RS"
      },
      {
        "code": "SSIQ",
        "name": "Itaqui",
        "display_name": "Itaqui, Itaqui, Brasil",
        "city": "Itaqui",
        "state": "RS"
      },
      {
        "code": "SSIR",
        "name": "Ibirubá",
        "display_name": "Ibirubá, Ibirubá, Brasil",
        "city": "Ibirubá",
        "state": "RS"
      },
      {
        "code": "SSJA",
        "name": "Santa Terezinha",
        "display_name": "Santa Terezinha, Joaçaba, Brasil",
        "city": "Joaçaba",
        "state": "SC"
      },
      {
        "code": "SSJI",
        "name": "Jardim",
        "display_name": "Jardim, Jardim, Brasil",
        "city": "Jardim",
        "state": "MS"
      },
      {
        "code": "SSJK",
        "name": "Júlio De Castilhos",
        "display_name": "Júlio De Castilhos, Júlio De Castilhos, Brasil",
        "city": "Júlio De Castilhos",
        "state": "RS"
      },
      {
        "code": "SSKG",
        "name": "Estância Santa Maria",
        "display_name": "Estância Santa Maria, Campo Grande, Brasil",
        "city": "Campo Grande",
        "state": "MS"
      },
      {
        "code": "SSKK",
        "name": "Capão Da Canoa",
        "display_name": "Capão Da Canoa, Capão Da Canoa, Brasil",
        "city": "Capão Da Canoa",
        "state": "RS"
      },
      {
        "code": "SSKM",
        "name": "Campo Mourão",
        "display_name": "Campo Mourão, Campo Mourão, Brasil",
        "city": "Campo Mourão",
        "state": "PR"
      },
      {
        "code": "SSKS",
        "name": "Brigadeiro Nero Moura",
        "display_name": "Brigadeiro Nero Moura, Cachoeira Do Sul, Brasil",
        "city": "Cachoeira Do Sul",
        "state": "RS"
      },
      {
        "code": "SSKU",
        "name": "Lauro Antônio Da Costa",
        "display_name": "Lauro Antônio Da Costa, Curitibanos, Brasil",
        "city": "Curitibanos",
        "state": "SC"
      },
      {
        "code": "SSKW",
        "name": "Cacoal",
        "display_name": "Cacoal, Cacoal, Brasil",
        "city": "Cacoal",
        "state": "RO"
      },
      {
        "code": "SSKZ",
        "name": "Carazinho",
        "display_name": "Carazinho, Carazinho, Brasil",
        "city": "Carazinho",
        "state": "RS"
      },
      {
        "code": "SSLG",
        "name": "São Luiz Gonzaga",
        "display_name": "São Luiz Gonzaga, São Luiz Gonzaga, Brasil",
        "city": "São Luiz Gonzaga",
        "state": "RS"
      },
      {
        "code": "SSLN",
        "name": "Helmuth Baungartem",
        "display_name": "Helmuth Baungartem, Lontras, Brasil",
        "city": "Lontras",
        "state": "SC"
      },
      {
        "code": "SSLO",
        "name": "Attillio Accorsi",
        "display_name": "Attillio Accorsi, Loanda, Brasil",
        "city": "Loanda",
        "state": "PR"
      },
      {
        "code": "SSLT",
        "name": "Gaudêncio Machado Ramos",
        "display_name": "Gaudêncio Machado Ramos, Alegrete, Brasil",
        "city": "Alegrete",
        "state": "RS"
      },
      {
        "code": "SSMJ",
        "name": "Maracaju",
        "display_name": "Maracaju, Maracaju, Brasil",
        "city": "Maracaju",
        "state": "MS"
      },
      {
        "code": "SSMR",
        "name": "Manoel Ribas",
        "display_name": "Manoel Ribas, Manoel Ribas, Brasil",
        "city": "Manoel Ribas",
        "state": "PR"
      },
      {
        "code": "SSMT",
        "name": "Mostardas",
        "display_name": "Mostardas, Mostardas, Brasil",
        "city": "Mostardas",
        "state": "RS"
      },
      {
        "code": "SSNB",
        "name": "Ariosto Da Riva",
        "display_name": "Ariosto Da Riva, Naviraí, Brasil",
        "city": "Naviraí",
        "state": "MS"
      },
      {
        "code": "SSNG",
        "name": "Montenegro",
        "display_name": "Montenegro, Montenegro, Brasil",
        "city": "Montenegro",
        "state": "RS"
      },
      {
        "code": "SSNH",
        "name": "Novo Hamburgo",
        "display_name": "Novo Hamburgo, Novo Hamburgo, Brasil",
        "city": "Novo Hamburgo",
        "state": "RS"
      },
      {
        "code": "SSNP",
        "name": "Nova Prata",
        "display_name": "Nova Prata, Nova Prata, Brasil",
        "city": "Nova Prata",
        "state": "RS"
      },
      {
        "code": "SSNQ",
        "name": "Nioaque",
        "display_name": "Nioaque, Nioaque, Brasil",
        "city": "Nioaque",
        "state": "MS"
      },
      {
        "code": "SSOE",
        "name": "Hélio Wasum",
        "display_name": "Hélio Wasum, São Miguel Do Oeste, Brasil",
        "city": "São Miguel Do Oeste",
        "state": "SC"
      },
      {
        "code": "SSOG",
        "name": "Arapongas",
        "display_name": "Arapongas, Arapongas, Brasil",
        "city": "Arapongas",
        "state": "PR"
      },
      {
        "code": "SSOI",
        "name": "Pederneiras",
        "display_name": "Pederneiras, Pederneiras, Brasil",
        "city": "Pederneiras",
        "state": "SP"
      },
      {
        "code": "SSOL",
        "name": "Padre Israel (Israel Batista De Carvalho)",
        "display_name": "Padre Israel (Israel Batista De Carvalho), Lavras, Brasil",
        "city": "Lavras",
        "state": "MG"
      },
      {
        "code": "SSOS",
        "name": "Osório",
        "display_name": "Osório, Osório, Brasil",
        "city": "Osório",
        "state": "RS"
      },
      {
        "code": "SBPO",
        "name": "Aeroporto Regional De Pato Branco - Professor Juvenal Loureiro Cardoso",
        "display_name": "Aeroporto Regional De Pato Branco - Professor Juvenal Loureiro Cardoso, Pato Branco, Brasil",
        "city": "Pato Branco",
        "state": "PR"
      },
      {
        "code": "SSPG",
        "name": "Santos Dumont",
        "display_name": "Santos Dumont, Paranaguá, Brasil",
        "city": "Paranaguá",
        "state": "PR"
      },
      {
        "code": "SSPI",
        "name": "Edu Chaves",
        "display_name": "Edu Chaves, Paranavaí, Brasil",
        "city": "Paranavaí",
        "state": "PR"
      },
      {
        "code": "SSPL",
        "name": "Palmeira Das Missões",
        "display_name": "Palmeira Das Missões, Palmeira Das Missões, Brasil",
        "city": "Palmeira Das Missões",
        "state": "RS"
      },
      {
        "code": "SSPM",
        "name": "Porto Murtinho",
        "display_name": "Porto Murtinho, Porto Murtinho, Brasil",
        "city": "Porto Murtinho",
        "state": "MS"
      },
      {
        "code": "SSPN",
        "name": "Paranaíba",
        "display_name": "Paranaíba, Paranaíba, Brasil",
        "city": "Paranaíba",
        "state": "MS"
      },
      {
        "code": "SSPS",
        "name": "Dimorvan Carraro",
        "display_name": "Dimorvan Carraro, Palmas, Brasil",
        "city": "Palmas",
        "state": "PR"
      },
      {
        "code": "SSPT",
        "name": "Brasílio Marques",
        "display_name": "Brasílio Marques, Palotina, Brasil",
        "city": "Palotina",
        "state": "PR"
      },
      {
        "code": "SSQC",
        "name": "Aguinaldo Pereira Lima",
        "display_name": "Aguinaldo Pereira Lima, Siqueira Campos, Brasil",
        "city": "Siqueira Campos",
        "state": "PR"
      },
      {
        "code": "SSQP",
        "name": "Monte Santo",
        "display_name": "Monte Santo, Monte Santo, Brasil",
        "city": "Monte Santo",
        "state": "BA"
      },
      {
        "code": "SSQT",
        "name": "Major Neodo S. Pereira",
        "display_name": "Major Neodo S. Pereira, Castro, Brasil",
        "city": "Castro",
        "state": "PR"
      },
      {
        "code": "SSRE",
        "name": "Realeza",
        "display_name": "Realeza, Realeza, Brasil",
        "city": "Realeza",
        "state": "PR"
      },
      {
        "code": "SSRF",
        "name": "Castro Alves",
        "display_name": "Castro Alves, Castro Alves, Brasil",
        "city": "Castro Alves",
        "state": "BA"
      },
      {
        "code": "SSRG",
        "name": "Aeroporto Estadual De Registro",
        "display_name": "Aeroporto Estadual De Registro, Registro, Brasil",
        "city": "Registro",
        "state": "SP"
      },
      {
        "code": "SSRS",
        "name": "Barreirinhas",
        "display_name": "Barreirinhas, Barreirinhas, Brasil",
        "city": "Barreirinhas",
        "state": "MA"
      },
      {
        "code": "SSRU",
        "name": "São Lourenço Do Sul",
        "display_name": "São Lourenço Do Sul, São Lourenço Do Sul, Brasil",
        "city": "São Lourenço Do Sul",
        "state": "RS"
      },
      {
        "code": "SSRZ",
        "name": "Dário Brasil Capoano De Oliveira",
        "display_name": "Dário Brasil Capoano De Oliveira, Rosário Do Sul, Brasil",
        "city": "Rosário Do Sul",
        "state": "RS"
      },
      {
        "code": "SSSB",
        "name": "São Borja",
        "display_name": "São Borja, São Borja, Brasil",
        "city": "São Borja",
        "state": "RS"
      },
      {
        "code": "SSSC",
        "name": "Santa Cruz Do Sul",
        "display_name": "Santa Cruz Do Sul, Santa Cruz Do Sul, Brasil",
        "city": "Santa Cruz Do Sul",
        "state": "RS"
      },
      {
        "code": "SSSD",
        "name": "Soledade",
        "display_name": "Soledade, Soledade, Brasil",
        "city": "Soledade",
        "state": "RS"
      },
      {
        "code": "SSSG",
        "name": "São Gabriel",
        "display_name": "São Gabriel, São Gabriel, Brasil",
        "city": "São Gabriel",
        "state": "RS"
      },
      {
        "code": "SSSS",
        "name": "São Francisco Do Sul",
        "display_name": "São Francisco Do Sul, São Francisco Do Sul, Brasil",
        "city": "São Francisco Do Sul",
        "state": "SC"
      },
      {
        "code": "SSST",
        "name": "Santiago",
        "display_name": "Santiago, Santiago, Brasil",
        "city": "Santiago",
        "state": "RS"
      },
      {
        "code": "SSSZ",
        "name": "Sertanópolis",
        "display_name": "Sertanópolis, Sertanópolis, Brasil",
        "city": "Sertanópolis",
        "state": "PR"
      },
      {
        "code": "SSTB",
        "name": "Três Barras",
        "display_name": "Três Barras, Três Barras, Brasil",
        "city": "Três Barras",
        "state": "SC"
      },
      {
        "code": "SSUM",
        "name": "Orlando De Carvalho",
        "display_name": "Orlando De Carvalho, Umuarama, Brasil",
        "city": "Umuarama",
        "state": "PR"
      },
      {
        "code": "SSUV",
        "name": "José Cleto",
        "display_name": "José Cleto, União Da Vitória, Brasil",
        "city": "União Da Vitória",
        "state": "PR"
      },
      {
        "code": "SBVC",
        "name": "Glauber De Andrade Rocha",
        "display_name": "Glauber De Andrade Rocha, Vitória Da Conquista, Brasil",
        "city": "Vitória Da Conquista",
        "state": "BA"
      },
      {
        "code": "SSVI",
        "name": "Ângelo Ponzoni",
        "display_name": "Ângelo Ponzoni, Videira, Brasil",
        "city": "Videira",
        "state": "SC"
      },
      {
        "code": "SSVL",
        "name": "Telêmaco Borba (Sbtl*)",
        "display_name": "Telêmaco Borba (Sbtl*), Telêmaco Borba, Brasil",
        "city": "Telêmaco Borba",
        "state": "PR"
      },
      {
        "code": "SSVN",
        "name": "Veranópolis",
        "display_name": "Veranópolis, Veranópolis, Brasil",
        "city": "Veranópolis",
        "state": "RS"
      },
      {
        "code": "SBJE",
        "name": "Comandante Ariston Pessoa",
        "display_name": "Comandante Ariston Pessoa, Cruz, Brasil",
        "city": "Cruz",
        "state": "CE"
      },
      {
        "code": "SSWS",
        "name": "Caçapava Do Sul",
        "display_name": "Caçapava Do Sul, Caçapava Do Sul, Brasil",
        "city": "Caçapava Do Sul",
        "state": "RS"
      },
      {
        "code": "SSXX",
        "name": "Municipal João Winckler",
        "display_name": "Municipal João Winckler, Xanxerê, Brasil",
        "city": "Xanxerê",
        "state": "SC"
      },
      {
        "code": "SSZR",
        "name": "Luis Alberto Lehr",
        "display_name": "Luis Alberto Lehr, Santa Rosa, Brasil",
        "city": "Santa Rosa",
        "state": "RS"
      },
      {
        "code": "SBPG",
        "name": "Comandante Antônio Amilton Beraldo",
        "display_name": "Comandante Antônio Amilton Beraldo, Ponta Grossa, Brasil",
        "city": "Ponta Grossa",
        "state": "PR"
      },
      {
        "code": "SWBC",
        "name": "Barcelos",
        "display_name": "Barcelos, Barcelos, Brasil",
        "city": "Barcelos",
        "state": "AM"
      },
      {
        "code": "SWBE",
        "name": "Walfrido Salmito De Almeida",
        "display_name": "Walfrido Salmito De Almeida, São Benedito, Brasil",
        "city": "São Benedito",
        "state": "CE"
      },
      {
        "code": "SWBG",
        "name": "André Antônio Maggi",
        "display_name": "André Antônio Maggi, Pontes E Lacerda, Brasil",
        "city": "Pontes E Lacerda",
        "state": "MT"
      },
      {
        "code": "SWBR",
        "name": "Borba",
        "display_name": "Borba, Borba, Brasil",
        "city": "Borba",
        "state": "AM"
      },
      {
        "code": "SWCA",
        "name": "Carauari",
        "display_name": "Carauari, Carauari, Brasil",
        "city": "Carauari",
        "state": "AM"
      },
      {
        "code": "SWCB",
        "name": "Campos Belos",
        "display_name": "Campos Belos, Campos Belos, Brasil",
        "city": "Campos Belos",
        "state": "GO"
      },
      {
        "code": "SWCQ",
        "name": "Costa Marques",
        "display_name": "Costa Marques, Costa Marques, Brasil",
        "city": "Costa Marques",
        "state": "RO"
      },
      {
        "code": "SWCZ",
        "name": "Ceres",
        "display_name": "Ceres, Ceres, Brasil",
        "city": "Ceres",
        "state": "GO"
      },
      {
        "code": "SWDM",
        "name": "Diamantino",
        "display_name": "Diamantino, Diamantino, Brasil",
        "city": "Diamantino",
        "state": "MT"
      },
      {
        "code": "SWDN",
        "name": "Dianópolis",
        "display_name": "Dianópolis, Dianópolis, Brasil",
        "city": "Dianópolis",
        "state": "TO"
      },
      {
        "code": "SWEI",
        "name": "Eirunepé",
        "display_name": "Eirunepé, Eirunepé, Brasil",
        "city": "Eirunepé",
        "state": "AM"
      },
      {
        "code": "SWEK",
        "name": "Canarana",
        "display_name": "Canarana, Canarana, Brasil",
        "city": "Canarana",
        "state": "MT"
      },
      {
        "code": "SWFN",
        "name": "Flores",
        "display_name": "Flores, Manaus, Brasil",
        "city": "Manaus",
        "state": "AM"
      },
      {
        "code": "SWFR",
        "name": "Formosa",
        "display_name": "Formosa, Formosa, Brasil",
        "city": "Formosa",
        "state": "GO"
      },
      {
        "code": "SWFX",
        "name": "São Félix Do Araguaia",
        "display_name": "São Félix Do Araguaia, São Félix Do Araguaia, Brasil",
        "city": "São Félix Do Araguaia",
        "state": "MT"
      },
      {
        "code": "SWGI",
        "name": "Gurupi",
        "display_name": "Gurupi, Gurupi, Brasil",
        "city": "Gurupi",
        "state": "TO"
      },
      {
        "code": "SWGN",
        "name": "Araguaína",
        "display_name": "Araguaína, Araguaína, Brasil",
        "city": "Araguaína",
        "state": "TO"
      },
      {
        "code": "SWHP",
        "name": "Frederico Carlos Müller",
        "display_name": "Frederico Carlos Müller, Água Boa, Brasil",
        "city": "Água Boa",
        "state": "MT"
      },
      {
        "code": "SWHT",
        "name": "Francisco Correa Da Cruz",
        "display_name": "Francisco Correa Da Cruz, Humaitá, Brasil",
        "city": "Humaitá",
        "state": "AM"
      },
      {
        "code": "SWII",
        "name": "Ipiranga",
        "display_name": "Ipiranga, Santo Antônio Do Içá, Brasil",
        "city": "Santo Antônio Do Içá",
        "state": "AM"
      },
      {
        "code": "SWIQ",
        "name": "Minaçu",
        "display_name": "Minaçu, Minaçu, Brasil",
        "city": "Minaçu",
        "state": "GO"
      },
      {
        "code": "SWJN",
        "name": "Juína",
        "display_name": "Juína, Juína, Brasil",
        "city": "Juína",
        "state": "MT"
      },
      {
        "code": "SWJW",
        "name": "Jataí",
        "display_name": "Jataí, Jataí, Brasil",
        "city": "Jataí",
        "state": "GO"
      },
      {
        "code": "SWKC",
        "name": "Cáceres",
        "display_name": "Cáceres, Cáceres, Brasil",
        "city": "Cáceres",
        "state": "MT"
      },
      {
        "code": "SWKO",
        "name": "Coari",
        "display_name": "Coari, Coari, Brasil",
        "city": "Coari",
        "state": "AM"
      },
      {
        "code": "SWKQ",
        "name": "Serra Da Capivara/São Raimundo Nonato",
        "display_name": "Serra Da Capivara/São Raimundo Nonato, São Raimundo Nonato, Brasil",
        "city": "São Raimundo Nonato",
        "state": "PI"
      },
      {
        "code": "SWKT",
        "name": "Catalão",
        "display_name": "Catalão, Catalão, Brasil",
        "city": "Catalão",
        "state": "GO"
      },
      {
        "code": "SWLB",
        "name": "Lábrea",
        "display_name": "Lábrea, Lábrea, Brasil",
        "city": "Lábrea",
        "state": "AM"
      },
      {
        "code": "SWLC",
        "name": "General Leite De Castro",
        "display_name": "General Leite De Castro, Rio Verde, Brasil",
        "city": "Rio Verde",
        "state": "GO"
      },
      {
        "code": "SWME",
        "name": "Mineiros",
        "display_name": "Mineiros, Mineiros, Brasil",
        "city": "Mineiros",
        "state": "GO"
      },
      {
        "code": "SWMW",
        "name": "Maués",
        "display_name": "Maués, Maués, Brasil",
        "city": "Maués",
        "state": "AM"
      },
      {
        "code": "SWNH",
        "name": "Aruanã",
        "display_name": "Aruanã, Aruanã, Brasil",
        "city": "Aruanã",
        "state": "GO"
      },
      {
        "code": "SWNK",
        "name": "Novo Campo",
        "display_name": "Novo Campo, Boca Do Acre, Brasil",
        "city": "Boca Do Acre",
        "state": "AM"
      },
      {
        "code": "SWNQ",
        "name": "Niquelândia",
        "display_name": "Niquelândia, Niquelândia, Brasil",
        "city": "Niquelândia",
        "state": "GO"
      },
      {
        "code": "SWNS",
        "name": "Anápolis",
        "display_name": "Anápolis, Anápolis, Brasil",
        "city": "Anápolis",
        "state": "GO"
      },
      {
        "code": "SBNV",
        "name": "Aeródromo Nacional De Aviação",
        "display_name": "Aeródromo Nacional De Aviação, Goiânia, Brasil",
        "city": "Goiânia",
        "state": "GO"
      },
      {
        "code": "SWOB",
        "name": "Fonte Boa",
        "display_name": "Fonte Boa, Fonte Boa, Brasil",
        "city": "Fonte Boa",
        "state": "AM"
      },
      {
        "code": "SWPG",
        "name": "Porto Dos Gaúchos",
        "display_name": "Porto Dos Gaúchos, Porto Dos Gaúchos, Brasil",
        "city": "Porto Dos Gaúchos",
        "state": "MT"
      },
      {
        "code": "SWPI",
        "name": "Parintins",
        "display_name": "Parintins, Parintins, Brasil",
        "city": "Parintins",
        "state": "AM"
      },
      {
        "code": "SWPK",
        "name": "Poconé",
        "display_name": "Poconé, Poconé, Brasil",
        "city": "Poconé",
        "state": "MT"
      },
      {
        "code": "SWPM",
        "name": "Pimenta Bueno",
        "display_name": "Pimenta Bueno, Pimenta Bueno, Brasil",
        "city": "Pimenta Bueno",
        "state": "RO"
      },
      {
        "code": "SWPR",
        "name": "Pires Do Rio",
        "display_name": "Pires Do Rio, Pires Do Rio, Brasil",
        "city": "Pires Do Rio",
        "state": "GO"
      },
      {
        "code": "SWPY",
        "name": "Primavera Do Leste",
        "display_name": "Primavera Do Leste, Primavera Do Leste, Brasil",
        "city": "Primavera Do Leste",
        "state": "MT"
      },
      {
        "code": "SWPZ",
        "name": "Ouriçanga De Abreu",
        "display_name": "Ouriçanga De Abreu, Posse, Brasil",
        "city": "Posse",
        "state": "GO"
      },
      {
        "code": "SWRO",
        "name": "Aeroclube De Rondônia",
        "display_name": "Aeroclube De Rondônia, Porto Velho, Brasil",
        "city": "Porto Velho",
        "state": "RO"
      },
      {
        "code": "SBSI",
        "name": "Presidente João Batista Figueiredo",
        "display_name": "Presidente João Batista Figueiredo, Sinop, Brasil",
        "city": "Sinop",
        "state": "MT"
      },
      {
        "code": "SWTO",
        "name": "Paraíso Do Tocantins",
        "display_name": "Paraíso Do Tocantins, Paraíso Do Tocantins, Brasil",
        "city": "Paraíso Do Tocantins",
        "state": "TO"
      },
      {
        "code": "SWTP",
        "name": "Santa Isabel Do Rio Negro",
        "display_name": "Santa Isabel Do Rio Negro, Santa Isabel Do Rio Negro, Brasil",
        "city": "Santa Isabel Do Rio Negro",
        "state": "AM"
      },
      {
        "code": "SWTS",
        "name": "Tangará Da Serra",
        "display_name": "Tangará Da Serra, Tangará Da Serra, Brasil",
        "city": "Tangará Da Serra",
        "state": "MT"
      },
      {
        "code": "SWTY",
        "name": "Taguatinga",
        "display_name": "Taguatinga, Taguatinga, Brasil",
        "city": "Taguatinga",
        "state": "TO"
      },
      {
        "code": "SWUA",
        "name": "São Miguel Do Araguaia",
        "display_name": "São Miguel Do Araguaia, São Miguel Do Araguaia, Brasil",
        "city": "São Miguel Do Araguaia",
        "state": "GO"
      },
      {
        "code": "SWUD",
        "name": "Deputado Oswaldo Tolentino",
        "display_name": "Deputado Oswaldo Tolentino, Cláudio, Brasil",
        "city": "Cláudio",
        "state": "MG"
      },
      {
        "code": "SWUZ",
        "name": "Brigadeiro Araripe Macedo",
        "display_name": "Brigadeiro Araripe Macedo, Luziânia, Brasil",
        "city": "Luziânia",
        "state": "GO"
      },
      {
        "code": "SWVC",
        "name": "Vila Rica",
        "display_name": "Vila Rica, Vila Rica, Brasil",
        "city": "Vila Rica",
        "state": "MT"
      },
      {
        "code": "SWWA",
        "name": "Porangatu",
        "display_name": "Porangatu, Porangatu, Brasil",
        "city": "Porangatu",
        "state": "GO"
      },
      {
        "code": "SWWU",
        "name": "Uruaçu",
        "display_name": "Uruaçu, Uruaçu, Brasil",
        "city": "Uruaçu",
        "state": "GO"
      },
      {
        "code": "SWXM",
        "name": "Regional Orlando Villas Boas",
        "display_name": "Regional Orlando Villas Boas, Matupá, Brasil",
        "city": "Matupá",
        "state": "MT"
      },
      {
        "code": "SWXQ",
        "name": "Lins",
        "display_name": "Lins, Lins, Brasil",
        "city": "Lins",
        "state": "SP"
      },
      {
        "code": "SWXV",
        "name": "Xavantina",
        "display_name": "Xavantina, Nova Xavantina, Brasil",
        "city": "Nova Xavantina",
        "state": "MT"
      },
      {
        "code": "SWYN",
        "name": "Apuí",
        "display_name": "Apuí, Apuí, Brasil",
        "city": "Apuí",
        "state": "AM"
      },
      {
        "code": "SBJH",
        "name": "São Paulo Catarina Aeroporto Executivo",
        "display_name": "São Paulo Catarina Aeroporto Executivo, São Roque, Brasil",
        "city": "São Roque",
        "state": "SP"
      },
      {
        "code": "SJXI",
        "name": "Santa Rita Do Sapucaí",
        "display_name": "Santa Rita Do Sapucaí, Santa Rita Do Sapucaí, Brasil",
        "city": "Santa Rita Do Sapucaí",
        "state": "MG"
      },
      {
        "code": "SNCP",
        "name": "Regional Do Planalto Serrano",
        "display_name": "Regional Do Planalto Serrano, Correia Pinto, Brasil",
        "city": "Correia Pinto",
        "state": "SC"
      },
      {
        "code": "SBTC",
        "name": "Una/Comandatuba",
        "display_name": "Una/Comandatuba, Una, Brasil",
        "city": "Una",
        "state": "BA"
      },
      {
        "code": "SDH2",
        "name": "Porto Alegre Do Norte",
        "display_name": "Porto Alegre Do Norte, Porto Alegre Do Norte, Brasil",
        "city": "Porto Alegre Do Norte",
        "state": "MT"
      },
      {
        "code": "SNOF",
        "name": "Ouro Fino",
        "display_name": "Ouro Fino, Ouro Fino, Brasil",
        "city": "Ouro Fino",
        "state": "MG"
      },
      {
        "code": "SDK7",
        "name": "Nova Andradina",
        "display_name": "Nova Andradina, Nova Andradina, Brasil",
        "city": "Nova Andradina",
        "state": "MS"
      },
      {
        "code": "SJ4Y",
        "name": "Morrinhos",
        "display_name": "Morrinhos, Morrinhos, Brasil",
        "city": "Morrinhos",
        "state": "GO"
      },
      {
        "code": "SI68",
        "name": "Gelindo Stefanuto",
        "display_name": "Gelindo Stefanuto, Campo Novo Do Parecis, Brasil",
        "city": "Campo Novo Do Parecis",
        "state": "MT"
      }
    ],
    searchType: 'contains',
  }

const styles = {
    input: 'w-full border py-2 px-4 text-lg outline-none rounded-md',
    listbox: 'bg-neutral-900 w-full text-slate-50 rounded-md',
    highlightedItem: 'bg-neutral-800',
    query: 'text-oldsilver-800 placeholder:text-slate-600',
    typeahead: 'text-slate-500',
    clearButton:
      'absolute inset-y-0 text-lg right-0 w-10 inline-flex items-center justify-center bg-netural-700 hover:text-red-500',
    noItems: 'cursor-default text-center my-20',
    match: 'font-semibold',
    groupHeading: 'px-5 py-3 text-pink-500',
  }

const Item = ({ item }) => {
  return (
    <div className='flex items-center cursor-pointer px-5 py-4'>
      <p>{item.display_name}</p>
    </div>
  )
}

const SearchBox = () => {
  return (
    <Turnstone
      id='search'
      name='search'
      autoFocus={true}
      typeahead={true}
      clearButton={true}
      debounceWait={250}
      listboxIsImmutable={true}
      maxItems={10}
      noItemsMessage="Não foram encontrados resultados"
      placeholder='Pesquisar...'
      listbox={listbox}
      styles={styles}
      Item={Item}
      plugins={[recentSearchesPlugin]}
    />
  )
}

export default SearchBox